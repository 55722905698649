import apiUrl from "../Constants/apiUrl";
import axiosInstance from "./AxiosInstance";

const api = "/api/v1/";

export const getKidAccess = async (reqbody) => {
  try {
    const response = await axiosInstance.post(
      `${api}${apiUrl.kidsaccess}`,
      reqbody
    );
    return response?.data;
  } catch (error) {
    console.error("An error occurred:", error.message);
    return error;
  }
};
export const getJoinWaitList = async (reqBody) => {
  try {
    const response = await axiosInstance.post(`${api}${apiUrl.join}`, reqBody);
    return response?.data;
  } catch (error) {
    console.error("An error occurred:", error.message);
    return error;
  }
};

export const sendEmail = async (reqBody) => {
  try {
    const response = await axiosInstance.post(`${api}${apiUrl.sendEmailId}`, reqBody);
    return response?.data;
  } catch (error) {
    console.error("An error occurred:", error.message);
    return error;
  }
};

