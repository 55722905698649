import axios from "axios";

const createBody = {
  headers: {
    "Content-Type": "application/json",
    Device: "127.0.0.1",
    "Accept-Language": "en_US",
    mode: "no-cors",
    "access-control-allow-origin": "*",
  },
};
const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const mainRequest = error.config;
    if (error.message === "Network Error" && !mainRequest._retry) {
      mainRequest._retry = true;
      return axiosInstance(mainRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
