import { useState } from "react";
import "../../Styles/joinus.css";
import { getJoinWaitList } from "../../apiServices/apiService";
const Joinus = ({ joinInputRef }) => {
  const [joinValue, setJoinValu] = useState("");
  const [showJoinedText, setShowJoinedText] = useState(false);
  const [newJoined, setNewJoined] = useState(false);
  const [alreadyJoined, setAlreadyJoined] = useState(false);

  const [error, setError] = useState("");
  const [joinClicked, setJoinClicked] = useState(false);
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleJoinChange = (e) => {
    setJoinValu(e.target.value);
    if (joinClicked) {
      if (!validateEmail(e.target.value)) {
        setError("Please enter a valid email address.");
      } else {
        setError("");
      }
    }
  };
  const handleJoinClick = (e) => {
    setJoinClicked(true);
    if (validateEmail(joinValue)) {
      fetchJoinData({ email: joinValue });
    } else {
      setError("Please enter a valid email address.");
    }
  };
  const fetchJoinData = async (body) => {
    const response = await getJoinWaitList(body);
    if (response?.data?.is_added) {
      setNewJoined(true);
    } else {
      setAlreadyJoined(true);
    }

    setShowJoinedText(true);
    setTimeout(() => {
      setJoinValu("");
      setShowJoinedText(false);
      setJoinClicked(false);
      setAlreadyJoined(false);
      setNewJoined(false);
    }, 6000);
  };

  return (
    <>
      <div className="w-[100%] lg:w-[85%] mx-auto mt-12 lg:mt-44 2xl:mt-64">
        {!showJoinedText ? (
          <div>
            <div className="heading text-center px-3">
              <h2 className="text-white text-[2.2rem] font-bold">
                Join us on DUBS!
              </h2>
              <p className="text-white text-[1.4rem] font-normal mx-6 md:mx-32 lg:mx-40">
                Enter your email and join the waitlist to enter the exciting
                world of <span>DUBS</span>
              </p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleJoinClick();
              }}
            >
              <div className="input-button mt-5 flex flex-col items-center justify-center mx-6 ">
                <input
                  className="w-[17rem] xs:w-[18rem] md:w-[22rem] border-[1.75px] border-[#FBF638] rounded-full text-[#fcfcfc] bg-transparent border-solid h-12 pl-5"
                  placeholder="Enter your email address"
                  type="email"
                  value={joinValue}
                  onChange={handleJoinChange}
                  ref={joinInputRef}
                  maxLength={200}
                />
                {/* <div className="min-h-8"> */}

                <p className="text-base mt-1 lg:mt-2 text-[#ff557e]">{error}</p>

                {/* </div> */}
              </div>
              {/* <div className="box-container-captcha">
          <input className="captcha-input" type="checkbox" />
          <p className="robot">I'm not a robot</p>
          <div className="logo-captcha">
            <svg
              width="31"
              height="30"
              viewBox="0 0 31 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.0906 14.9789C30.0899 14.7631 30.0849 14.5485 30.0753 14.335V2.15984L26.7093 5.52576C23.9545 2.15375 19.7637 0 15.0697 0C10.1847 0 5.84492 2.33169 3.10156 5.94269L8.61873 11.5179C9.15941 10.5179 9.92751 9.65906 10.8536 9.01039C11.8168 8.25873 13.1816 7.64415 15.0695 7.64415C15.2976 7.64415 15.4736 7.6708 15.603 7.72101C17.9421 7.90563 19.9696 9.19653 21.1635 11.0702L17.2581 14.9755C22.2047 14.9561 27.7928 14.9447 30.0902 14.978"
                fill="#1C3AA9"
              />
              <path
                d="M14.9789 0.000610352C14.7631 0.00131601 14.5485 0.00633868 14.335 0.0159818H2.15983L5.52576 3.38191C2.15375 6.13673 0 10.3275 0 15.0216C0 19.9065 2.33173 24.2463 5.94269 26.9897L11.5179 21.4725C10.5179 20.9318 9.65906 20.1637 9.01039 19.2376C8.25877 18.2744 7.64415 16.9096 7.64415 15.0217C7.64415 14.7937 7.6708 14.6176 7.72101 14.4883C7.90563 12.1492 9.19653 10.1216 11.0702 8.92779L14.9755 12.8331C14.9561 7.88654 14.9447 2.29845 14.978 0.00103747"
                fill="#4285F4"
              />
              <path
                d="M0 15.0211C0.00072284 15.2369 0.00569389 15.4514 0.0153656 15.665V27.8402L3.38129 24.4742C6.13611 27.8462 10.3269 30 15.021 30C19.9059 30 24.2457 27.6683 26.9891 24.0573L21.4719 18.4821C20.9312 19.4821 20.1631 20.3409 19.237 20.9896C18.2738 21.7413 16.909 22.3558 15.0211 22.3558C14.7931 22.3558 14.617 22.3292 14.4877 22.279C12.1486 22.0944 10.121 20.8035 8.92718 18.9298L12.8325 15.0245C7.88593 15.0439 2.29784 15.0553 0.000429605 15.022"
                fill="#ABABAB"
              />
            </svg>
         
          </div>
         
        </div> */}

              <div className="join text-center cursor-pointer flex justify-center items-center mt-6 lg:mt-5 mx-6">
                <button
                  className="bg-[#FBF638] px-4 py-2 rounded-full font-bold w-[17rem] xs:w-[18rem] md:w-[22rem] h-12 text-[18px] text-[#350266] btn-hvr"
                  type="submit"
                >
                  Join
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            {newJoined ? (
              <div className="heading text-center">
                <h2 className="text-white text-[2.2rem] font-bold">
                  You’re on the list!
                </h2>
                <p className="text-white text-[1.5rem] font-normal">
                  Keep an eye on your email for further instructions
                </p>
              </div>
            ) : (
              ""
            )}
            {alreadyJoined ? (
              <div className="heading text-center px-3">
                <h2 className="text-white text-[2.2rem] font-bold">
                  You're Already on the List!
                </h2>
                <p className="text-white text-[1.5rem] font-normal lg:mx-auto lg:w-[60%]">
                  It looks like you’ve already submitted your email. No need to
                  reapply— we’ll be in touch with your invite code soon.
                </p>
                <p className="text-white text-[1.5rem] font-normal mt-5">
                  Thank you for your patience!
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Joinus;
