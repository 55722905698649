import { useEffect, useState } from "react";
import "../../Styles/Parentalconfirmation.css";
import PILImage from "../PILImage";
const Parentalaccepted = () => {
  useEffect(() => {
    document.body.style.background = "#350266";
  }, []);
  return (
    <>
      <div className="parental-confirmation">
        {/* <img className='background-image' src="/images/background-home-icons.svg" /> */}
        <div className="logo-part flex pt-5 justify-center">
          <img src="/images/dubslogo.svg" />
        </div>

        <div className="content-part flex  flex-col  justify-center items-center h-full lg:h-auto">
          <PILImage
            lowQaulityImg="/images/accept_low.png"
            highQualityImg="/images/parental_accepted.svg"
            padding="5rem"
          />
          <div className="content text-center">
            <div className="heading">
              <h2>Access Granted</h2>
            </div>
            <div className="text-center">
              <p>Your child now has access to all features on DUBS.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Parentalaccepted;
