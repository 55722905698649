import React, { useState } from "react";
import "../Styles/Parentalconfirmation.css";
const PILImage = ({ lowQaulityImg, highQualityImg, padding = 0 }) => {
  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div className="image-part pt-[5rem] flex justify-center items-center relative mt-4">
      <img
        alt="accepted"
        src={lowQaulityImg}
        width="230"
        height="230"
        className={`low-quality ${
          loading ? "visible" : "invisible"
        } absolute top-[9.5rem] md:top-[8rem] xl:top-[8.7rem] left-1/2 -translate-x-[50%] -translate-y-[50%]`}
      />
      <img
        alt="accepted"
        src={highQualityImg}
        width="240"
        height="240"
        onLoad={handleImageLoad}
        className="high-quality"
      />
    </div>
  );
};

export default PILImage;
