import "../../Styles/Parentalconfirmation.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PILImage from "../PILImage";
const Parentaldenied = () => {
  const location = useLocation();
  const data = location.state;
  useEffect(() => {
    document.body.style.background = "#350266";
  }, []);

  return (
    <>
      <div className="parental-confirmation">
        <div className="logo-part flex pt-5 justify-center">
          <img src="/images/dubslogo.svg" width={140} height={40} />
        </div>

        <div className="content-part flex  flex-col  justify-center items-center h-full lg:h-auto">
          <PILImage
            lowQaulityImg="/images/denied_low.png"
            highQualityImg="/images/parental_verfication_success.svg"
            padding="5rem"
          />
          <div className="content w-[90vw] md:w-[640px] text-center">
            <div className="heading  mb-2">
              <h2>Access Denied</h2>
            </div>
            <div>
              <p className=" mb-4 leading-[28px]">
                You have refused to allow your child’s{" "}
                <span className="font-bold"> {data?.data?.profile_name} </span>{" "}
                <br></br>participation on DUBS. Your child will still be able to
                use their account,<br></br>and access most features, including
                moderated text chat.
              </p>
              <p className="leading-[28px]  mb-2 lg:mb-0">
                You may request that we delete your child's account, and/or
                request that we delete your email address at any time. To take
                any of these actions, please contact customer service <br></br>
                at{" "}
                <a href="mailto:support@dubs.gg" className="text-primary">
                  support@dubs.gg{" "}
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Parentaldenied;
