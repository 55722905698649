import "../../Styles/footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer pb-[1rem] pt-[1rem]">
        <div className="logo-footer flex justify-center">
          <img
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
            className="h-12"
          />
        </div>
        <div className="flex justify-center">
          <p className="mt-2 copyright">Copyright ©2024 </p>
        </div>
        <div className="privacy flex justify-center mt-4 gap-[0.5rem]">
          <p>
            <a href="/help/privacypolicy">Privacy Policy </a>
          </p>
          <p>•</p>
          <p>
            <a href="/help/termsofuse">Terms & Conditions</a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
