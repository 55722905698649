import { useEffect, useState } from "react";
import "../../Styles/Parentalconfirmation.css";
import { useNavigate } from "react-router-dom";
import PILImage from "../PILImage";
import { useLocation } from "react-router-dom";
import { getKidAccess } from "../../apiServices/apiService";
const Parentalconfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState({});

  useEffect(() => {
    document.body.style.background = "#350266";
  }, []);
  useEffect(() => {
    const urlparams = window.location.href.split("?");

    setStatus(urlparams.length > 0 ? urlparams[1] : "");
  }, [location]);

  const fetchConsent = async (consent) => {
    const reqBody = {
      status,
    };
    const body = {
      consent,
      reqBody,
    };
    const response = await getKidAccess(body);
    if (response?.status === "SUCCESS") {
      if (consent === "Yes") {
        navigate("/parentalaccepted");
      } else {
        navigate("/parentaldenied", { state: response });
      }
    } else if (response?.status === "FAILURE") {
      navigate("/verificationfailure", { state: response });
    }
  };

  return (
    <>
      <div className="parental-confirmation">
        <div className="logo-part flex pt-5 justify-center">
          <img src="/images/dubslogo.svg" width={140} height={40} />
        </div>

        <div className="content-part flex  flex-col  justify-center items-center">
          <PILImage
            lowQaulityImg="/images/accept_low.png"
            highQualityImg="/images/parental_accepted.svg"
          />

          <div className="content w-[90vw] md:w-[640px]">
            <div className="heading text-center mb-3">
              <h2>DUBS Parental verification</h2>
            </div>
            <div className="">
              <p className="mb-3 text-center">
                DUBS is a social platform designed for gamers, offering a safe
                and rewarding way to discover, connect, and stay connected with
                friends. <br className="lg:hidden"></br>When your child first
                created an account on DUBS, we collected the following
                information: username, password, birthdate, gender, favorite
                games, interests.<br className="lg:hidden"></br> By clicking the
                "Yes" button, you will be granting your child access to all
                features on DUBS. By allowing your child to maintain an account
                on DUBS, you agree that
              </p>
              <p className="mb-2">
                (a) You and your child agree to the DUBS{" "}
                <a className="text-primary" href="/help/termsofuse">
                  <span>Terms of service</span>
                </a>{" "}
                and{" "}
                <a className="text-primary" href="/help/privacypolicy">
                  <span>Privacy policy</span>
                </a>{" "}
                including arbitration
              </p>
              <p className="mb-4">
                (b) You will supervise and be solely responsible for your
                child’s use of DUBS.
              </p>

              <p className="mb-2 text-center">
                If you do not wish to proceed, your child will still be able to
                use their account, and access most features, including moderated
                text chat.
              </p>
            </div>

            <div className="button-part gap-5">
              <div
                onClick={() => {
                  fetchConsent("No");
                }}
                className=" cursor-pointer no"
              >
                <p>No</p>
              </div>
              <div
                onClick={() => {
                  fetchConsent("Yes");
                }}
                className="yes bg-primary cursor-pointer
"
              >
                <p className="ext-[#201e30] font-bold ">Yes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Parentalconfirmation;
