import "../../Styles/Term.css";
import Footer from "./Footer.js";
const Terms = () => {
  return (
    <>
      <body id="term-body">
        <div className="term-body">
          <div class="dubslogo-header grid lg:grid-cols-3 gap-0 lg:gap-4 py-2 lg:py-4 px-14">
            <div class="dubs-logo mt-2 flex lg:block justify-center">
              <img
                className="h-12"
                src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
              />
            </div>
            <div class="header">
              <h1 className="text-center">Terms of Service</h1>
            </div>
          </div>
          {/* <div className='dubslogo-header  flex flex-col justify-center'> */}
          {/* <div className=' mt-[1rem] mb-[2rem] dubs-web-logo'>
          <div className='flex   w-full ml-[2rem] mt-[1rem]'>
       <img src="/images/Dubs-web-logo.png"/>

       <div className='flex w-full item-center justify-center'>
       <h1 className='ml-[-15rem]'>Terms of Service</h1>
       </div>
      
        </div>
        </div> */}

          {/* <div className='dubs-mobile-logo'>
        <div className='logo-header mt-5 flex justify-center'>
          <img src="/images/dubslogo.svg" />
        </div>
        <div className='header flex mt-1  justify-center'>
          <h1>Terms of Service
          </h1>

        </div>
        </div>
      </div> */}
          <div className="Terms-page mt-[2rem] mb-[2rem] mx-5">
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>1. INTRODUCTION</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  Welcome to DUBS.GG, provided by OneXP LLC, its subsidiaries,
                  and affiliated companies (collectively “<strong>DUBS</strong>
                  ,” “<strong>we</strong>,” “<strong>us</strong>,” or “
                  <strong>our</strong>”). These Terms of Service (“
                  <strong>Terms of Service</strong>”) govern your access to and
                  use of DUBS’ products, websites, applications, features,
                  content, and all related services and technologies
                  (collectively, the “<strong>Service</strong>”).
                </p>
              </div>
              <div className="sub-data">
                <p>
                  By accessing or using the Service, you acknowledge and agree
                  that you have read, understood, and agree to be bound by these
                  Terms of Service, our{" "}
                  <a href="/help/privacypolicy">
                    <span>Privacy Policy</span>
                  </a>
                  , and any other applicable policies that apply to your use of
                  the Service and are incorporated into these Terms of Service.
                  These documents govern your relationship with us and use of
                  the Service. If you do not agree to these Terms of Service in
                  their entirety, then you must not access or use the Service.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  We reserve the right to modify these Terms of Service at any
                  time at our sole discretion. For significant changes to the
                  Terms of Service, we will endeavor to provide reasonable
                  notice before the new terms take effect. Notice will be
                  communicated through our website, email, or other prominent
                  channels. We retain sole discretion in determining what
                  constitutes a material change. For minor changes, we may
                  update the Terms of Service without prior notice. Any changes
                  shall be effective as of the “Last Updated” date listed at the
                  top of the Terms of Service.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  We recommend that you regularly review these Terms of Service
                  to stay informed of any changes. Your continued use of the
                  Service after any revisions become effective constitutes your
                  acceptance of and agreement to be bound by the updated terms.
                  If you do not agree to the new terms, you must discontinue
                  using the Service.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  Some DUBS services or features may have additional terms
                  specific to their use. In the event of a conflict between
                  these Terms of Service and any additional terms, the
                  additional terms will control for that specific service or
                  feature.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>IMPORTANT LEGAL NOTICE: </p>
            </div>
            <div className="sub-description">
              <div className="strong">
                <p>
                  The “Dispute Resolution” section of these Terms of Service
                  contains a binding arbitration provision and class action
                  waiver. These provisions significantly impact your legal
                  rights, including your ability to bring or participate in a
                  lawsuit in a court of law. We strongly encourage you to read
                  this section carefully and thoroughly before agreeing to these
                  Terms of Service.
                </p>
              </div>
            </div>

            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>2. ELIGIBILITY AND ACCOUNTS</p>
            </div>
            <div className="sub-description">
              <div className="sub-heading">
                <p>2.1 Age Requirements.</p>
              </div>
              <div className="sub-data">
                <p>
                  You must be at least thirteen (13) years of age to access or
                  use the Service. If you are under the age of thirteen (13),
                  then you are expressly prohibited from accessing or using any
                  part of the Service. Users between 13 and 17 years old (“Teen
                  User”) may only use the Service under the supervision of a
                  parent or legal guardian who must review and agree to be bound
                  by these Terms of Service prior to using the Services. BY
                  PERMITTING A MINOR UNDER THE AGE OF 18 TO USE THE SERVICE, THE
                  MINOR’S PARENT OR LEGAL GUARDIAN BECOMES SUBJECT TO THESE
                  TERMS OF SERVICE AND AGREES TO BE FULLY RESPONSIBLE FOR: (A)
                  THE MINOR’S COMPLIANCE WITH THESE TERMS OF SERVICE AND ANY
                  OTHER APPLICABLE POLICIES OR GUIDELINES; (B) ALL CHARGES
                  INCURRED BY THE MINOR’S USE OF THE SERVICE; (C) ANY LEGAL
                  LIABILITY ARISING FROM THE MINOR’S USE OF THE SERVICE; AND (D)
                  ENSURING THE MINOR’S USE OF THE SERVICE IS APPROPRIATE AND IN
                  COMPLIANCE WITH APPLICABLE LAWS. We reserve the right to
                  request proof of age at any time and to terminate or suspend
                  accounts where we have reason to believe that the user does
                  not meet the age requirements.
                </p>
              </div>
              <div className="sub-heading">
                <p>2.2 Account Creation and Security.</p>
              </div>
              <div className="sub-data">
                <p>
                  To access certain features of the Service, you must create an
                  account. When creating an account, you agree to provide
                  accurate, current, and complete information, and to maintain
                  and promptly update your account information to keep it
                  accurate, current, and complete. We reserve the right to
                  suspend or terminate your account if we have reasonable
                  grounds to believe that any information you have provided is
                  inaccurate, outdated, or incomplete.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  You are solely responsible for maintaining the confidentiality
                  of your account credentials, including your password, and for
                  all activities occurring under your account. In the event of
                  any unauthorized use or security breach, you must immediately
                  notify us at{" "}
                  <a href="mailto:security@dubs.gg">
                    <span>security@dubs.gg</span>
                  </a>
                  . If you are locked out of your account, we will attempt to
                  contact you using the email address or phone number associated
                  with your account; however, if your account is compromised or
                  you no longer have access to your associated email account or
                  phone number, then we may be unable to restore your access to
                  your account.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  We reserve the right to suspend or terminate accounts showing
                  signs of unauthorized or fraudulent access, use, or any
                  unusual activity in order to protect our services and other
                  users. To enhance your account security, we strongly recommend
                  using a strong, unique password, enabling two-factor
                  authentication if available, regularly updating your account
                  information, and logging out when using shared devices.
                </p>
              </div>
              <div className="sub-heading">
                <p>2.3 Account Restrictions.</p>
              </div>
              <div className="sub-data">
                <p>
                  You agree to refrain from commercially exploiting your account
                  or its content, including, without limitation, through
                  licensing, selling, transferring, or assigning without DUBS'
                  express written consent. We reserve the right to reclaim
                  usernames on behalf of businesses or individuals holding legal
                  claim or trademark to those usernames.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>3. USE OF THE SERVICE</p>
            </div>
            <div className="sub-description">
              <div className="sub-heading">
                <p>3.1 License Grant.</p>
              </div>
              <div className="sub-data">
                <p>
                  Subject to your compliance with these Terms of Service, DUBS
                  grants you a limited, non-exclusive, non-transferable,
                  non-sublicensable, personal, non-assignable, and revocable
                  license to access and use the Service solely for your
                  personal, non-commercial purposes. This license remains in
                  effect unless terminated or suspended by DUBS in accordance
                  with these Terms of Service. Your continued access to and use
                  of the Service is contingent upon your ongoing compliance with
                  these Terms of Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.2 Prohibited Activities.</p>
              </div>
              <div className="sub-data">
                <p>
                  You agree to use the Service only for lawful purposes and in
                  compliance with these Terms of Service, our other applicable
                  policies and guidelines, and all applicable laws, rules and
                  regulations. This includes, but is not limited to, respecting
                  intellectual property rights, refraining from any unlawful,
                  abusive, harassing, or fraudulent activities, and not
                  interfering with the Service or other user’s access or use
                  thereof.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  The following activities are strictly prohibited and you agree
                  not to engage in any of the following: (a) violating any laws
                  or the rights of others; (b) impersonating any person or
                  entity, or falsely stating or misrepresenting your affiliation
                  with a person or entity; (c) interfering with or disrupting
                  the operation of the Service or the servers or networks used
                  to make the Service available; (d) attempting to gain
                  unauthorized access to the Service, user accounts, or
                  connected computer systems or networks; (e) collecting or
                  harvesting any personally identifiable information from the
                  Service; (f) using the Service for any commercial solicitation
                  purposes without our prior written consent; (g) circumventing,
                  disabling, or otherwise interfering with security-related
                  features of the Service; (h) using any robot, spider, site
                  search/retrieval application, or other manual or automated
                  means to access, retrieve, index, “scrape,” “data mine,” or in
                  any way reproduce or otherwise circumvent the navigational
                  structure or presentation of the Service; (i) posting,
                  transmitting, or linking to any viruses, malware, Trojan
                  horses, or other harmful or malicious code or content; (j)
                  engaging in any activity that could damage, disable,
                  overburden, or impair the Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.3 User Content.</p>
              </div>
              <div className="sub-data">
                <p>
                  <strong>“User Content”</strong> refers to any content
                  submitted, posted, or displayed by users on the Service.
                </p>
              </div>
              <div className="sub-data">
                <p>You may not post User Content that:</p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(a)</p>
                <p>
                  is illegal, abusive, harassing, threatening, or otherwise
                  objectionable;
                </p>
              </div>
              <div className="sub-data  flex gap-2 ">
                <p>(b)</p>
                <p>
                  infringes or violates any patent, trademark, trade secret,
                  copyright, right of publicity, intellectual property right, or
                  any other right of any party;
                </p>
              </div>
              <div className="sub-data  flex gap-2">
                <p>(c)</p>
                <p>
                  {" "}
                  contains sexually explicit material, excessive violence, or
                  offensive subject matter;{" "}
                </p>
              </div>
              <div className="sub-data  flex gap-2 ">
                <p>(d)</p>
                <p>
                  {" "}
                  promotes discrimination, bigotry, racism, hatred, harassment,
                  or harm against any individual or group;{" "}
                </p>
              </div>
              <div className="sub-data  flex gap-2">
                <p>(e)</p>
                <p>
                  {" "}
                  contains unsolicited promotions, political campaigning,
                  advertising, or solicitations;{" "}
                </p>
              </div>
              <div className="sub-data  flex gap-2">
                <p>(f)</p>
                <p>
                  discloses private information of any third party, including
                  addresses, phone numbers, email addresses, or other personal
                  details;
                </p>
              </div>
              <div className="sub-data  flex gap-2">
                <p>(g)</p>
                <p>
                  {" "}
                  contains viruses, corrupted data, or other harmful,
                  disruptive, or destructive files or content;
                </p>
              </div>
              <div className="sub-data  flex gap-2">
                <p>(h)</p>
                <p>
                  {" "}
                  is unrelated or otherwise irrelevant to the topic or context
                  in which it is posted.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  By submitting, posting, or displaying User Content on,
                  through, or otherwise in connection with the Service, you
                  grant DUBS a worldwide, non-exclusive, royalty-free,
                  sublicensable, and transferable license to use, copy,
                  reproduce, distribute, modify, adapt, translate, prepare
                  derivative works of, publish, display, and perform such User
                  Content in connection with the Service and DUBS’ business
                  operations, including for promotion and redistribution in any
                  existing or future media formats and media channels. This
                  license authorizes DUBS to make your User Content available
                  worldwide, let others do the same, and make it available to
                  other entities or individuals for syndication, broadcast,
                  distribution, promotion, or publication. You further agree
                  that this license includes the right for DUBS to use such User
                  Content in connection with providing, promoting, and otherwise
                  improving the Service. You represent and warrant that you have
                  all necessary rights, power, and authority necessary to grant
                  this license and all related rights and that your User Content
                  does not violate any third-party rights or applicable laws.
                  You retain any copyright that you may hold in User Content
                  that you submit through the Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.4 Moderation.</p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS uses a multi-layered approach to enforce these Terms of
                  Service, combining automated systems, user reports, and a
                  moderation team to monitor and review accounts and content to
                  identify potential violations. DUBS reserves the right, but
                  not the obligation, to monitor, remove, edit, or refuse to
                  display any content (including User Content) at its sole
                  discretion. We may also terminate or suspend access to any
                  part of the Service at any time, including in cases of
                  suspected or actual violations of these Terms of Service.
                  While we strive for consistent and fair policy enforcement,
                  the volume of content on the Service means that we cannot
                  monitor or control all interactions or content, and we do not
                  guarantee removal of all violating material. DUBS does not
                  endorse and is not responsible for any User Content. Users
                  bear all risks associated with User Content, including but not
                  limited to accuracy, reliability, potential infringement of
                  intellectual property rights, exposure to offensive material,
                  and any resulting loss or damage.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.5 User Interactions.</p>
              </div>
              <div className="sub-data">
                <p>
                  When using the Service to connect with fellow gamers, you
                  agree to treat all users with respect and courtesy,
                  communicate in a family-friendly manner appropriate for all
                  ages, and use the Service solely for its intended purpose of
                  finding gaming buddies. By adhering to these guidelines, we
                  collectively foster a positive community and enhance the
                  gaming experience for everyone. You are solely responsible for
                  all interactions with other users of the Service. While we
                  reserve the right, at our sole discretion, to monitor disputes
                  between users, we are under no obligation to do so or to
                  become involved in such disputes or to take any action in
                  regard to such disputes. We shall not be liable for any
                  claims, damages, or losses arising from user interactions or
                  disputes. You agree to release us from any claims, demands,
                  and damages (actual and consequential) arising out of or in
                  any way connected with a dispute you have with another user.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.6 Reporting Violations.</p>
              </div>
              <div className="sub-data">
                <p>
                  If you become aware of any violation of these Terms of Service
                  by other users, we encourage you to report it promptly to{" "}
                  <a href="mailto:support@dubs.gg">
                    <span>support@dubs.gg.</span>
                  </a>{" "}
                  When reporting, please provide as much detail as possible,
                  including the username of the user in question, the nature of
                  the violation, relevant dates or times, and any supporting
                  evidence. We take all reports seriously and will investigate
                  them in a timely manner, while reserving the right to take
                  appropriate action against violators. Please note that
                  submitting false or malicious reports may itself constitute a
                  violation of these Terms of Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.7 Safety and Well-Being.</p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS is committed to providing a safe and positive environment
                  for all users, especially younger users. We collaborate with
                  safety organizations and implement various measures to protect
                  users. However, we cannot guarantee complete safety and urge
                  all users to exercise caution and follow safe online
                  practices. Parents and guardians are encouraged to monitor and
                  guide their children’s use of the Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>3.8 Feedback.</p>
              </div>
              <div className="sub-data">
                <p>
                  If you provide DUBS with any feedback, suggestions, ideas, or
                  other input regarding the Service (collectively,{" "}
                  <strong>“Feedback”</strong>), you hereby grant DUBS a
                  non-exclusive, worldwide, perpetual, irrevocable,
                  transferable, sublicensable, and royalty-free license to use,
                  exploit, modify, adapt, publish, distribute, and incorporate
                  such Feedback into its products, services, and other
                  intellectual property. DUBS may use and exploit such Feedback
                  for any purpose, including, but not limited to, improving and
                  marketing its products and services, without any restrictions,
                  attribution, or compensation to you. You represent that you
                  have the right to grant this license and that the Feedback
                  does not infringe upon any third-party rights. DUBS is under
                  no obligation to consider or implement any Feedback.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>4. INTELLECTUAL PROPERTY RIGHTS</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  4.1 The Service, including but not limited to its original
                  content, features, functionality, design, and underlying
                  technology, is and will remain the exclusive property of DUBS
                  and its licensors. The Service is protected by copyright,
                  trademark, and other laws of the United States and foreign
                  countries.
                </p>
              </div>
              <div className="sub-data">
                <p>Users may not: </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(a)</p>
                <p>
                  use DUBS's trademarks, logos, or trade dress in connection
                  with any product or service without prior express written
                  consent from an authorized representative of DUBS;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(b)</p>
                <p>
                  copy, modify, distribute, sell, lease, reverse engineer, or
                  otherwise attempt to extract the source code of the Service,
                  except where prohibited by law;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(c)</p>
                <p>
                  remove any copyright, trademark, or other proprietary notices
                  from any portion of the Service;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(d)</p>
                <p>
                  reproduce, duplicate, copy, sell, resell, or exploit for any
                  commercial purposes any portion of the Service, use of the
                  Service, or access to the Service;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(e)</p>
                <p>
                  use any metatags or any other "hidden text" utilizing DUBS'
                  name or trademarks;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(f)</p>
                <p>use the Service for any illegal or unauthorized purpose;</p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(g)</p>
                <p>
                  attempt to gain unauthorized access to any portion or feature
                  of the Service or;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(h)</p>
                <p>
                  use the Service in any manner that could damage, disable,
                  overburden, or impair it.
                </p>
              </div>
              <div className="sub-heading">
                <p>4.2 Copyright Policy (DMCA).</p>
              </div>
              <div className="sub-data">
                <p>
                  We respect the intellectual property rights of others and
                  expect users of the Service to do the same. We will respond to
                  notices of alleged copyright infringement that comply with
                  applicable law and are properly provided to us. If you believe
                  that your copyrighted work has been copied in a way that
                  constitutes copyright infringement, please provide our
                  copyright agent with the following information:
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(a)</p>
                <p>
                  a physical or electronic signature of the copyright owner or a
                  person authorized to act on their behalf;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(b)</p>
                <p>
                  identification of the copyrighted work claimed to have been
                  infringed;
                </p>
              </div>
              <div className="sub=data flex gap-2">
                <p>(c)</p>
                <p>
                  identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit us to locate
                  the material;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(d)</p>
                <p>
                  your contact information, including your address, telephone
                  number, and an email address;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(e)</p>
                <p>
                  a statement by you that you have a good faith belief that use
                  of the material in the manner complained of is not authorized
                  by the copyright owner, its agent, or the law; and
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(f)</p>
                <p>
                  a statement that the information in the notification is
                  accurate, and, under penalty of perjury, that you are
                  authorized to act on behalf of the copyright owner.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  Our designated copyright agent for notice of alleged copyright
                  infringement can be reached at:
                </p>
              </div>
              <div className="sub-data">
                <div className="flex">
                  <p className="strong">Attention:</p>
                  <p className="ml-2 sub-data">OneXP Copyright Agent</p>
                </div>
                <div className="flex">
                  <p>Email:</p>
                  <a href="mailto:support@dubs.gg" className="ml-8">
                    <span>support@dubs.gg</span>
                  </a>
                </div>
                <div className="flex">
                  <p className="strong">Address:</p>
                  <p className="ml-2 mt-[0.5rem]">
                    1042 Princeton Drive <br />
                    Suite B <br />
                    Marina Del Rey <br />
                    Los Angeles, CA 90292
                  </p>
                </div>
              </div>

              <div className="sub-data">
                <p>
                  If you believe your content was wrongly removed due to a
                  mistake or misidentification, you may submit a counter
                  notification to our copyright agent. The counter notification
                  must include:
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(a)</p>
                <p>your physical or electronic signature;</p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(b)</p>
                <p>
                  identification of the material that has been removed or to
                  which access has been disabled and the location at which the
                  material appeared before it was removed or access to it was
                  disabled;
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(c)</p>
                <p>
                  a statement under penalty of perjury that you have a good
                  faith belief that the material was removed or disabled as a
                  result of mistake or misidentification; and
                </p>
              </div>
              <div className="sub-data flex gap-2">
                <p>(d)</p>
                <p>
                  your name, address, and telephone number, and a statement that
                  you consent to the jurisdiction of the federal district court
                  for the judicial district in which your address is located, or
                  if your address is outside of the United States, for any
                  judicial district in which DUBS may be found, and that you
                  will accept service of process from the person who provided
                  notification of infringement or an agent of such person.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  If you knowingly misrepresent that material or activity on the
                  website was removed or disabled due to mistake or
                  misidentification, you may be held liable for any resulting
                  damages. We reserve the right to disable or terminate the
                  accounts of users who repeatedly infringe, or are repeatedly
                  accused of infringing, copyrights or other intellectual
                  property rights. This policy will be enforced at our
                  discretion based on the circumstances of each case.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>5. PRIVACY AND DATA</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  We value and respect your privacy. Our Privacy Policy,
                  accessible at{" "}
                  <a href="/help/privacypolicy">
                    <span>https://dubs.gg/help/privacypolicy</span>
                  </a>
                  , details our practices regarding the collection, use,
                  storage, and protection of your personal information. By using
                  the Service, you acknowledge that you have read and understood
                  our Privacy Policy, and you consent to the collection, use,
                  and processing of your data as described therein. We encourage
                  you to review our Privacy Policy periodically, as it may be
                  updated from time to time. Your continued use of the Service
                  after any changes to the Privacy Policy constitutes your
                  acceptance of those changes.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>6. PAYMENTS</p>
            </div>
            <div className="sub-description">
              <div className="sub-heading">
                <p>6.1 Fees and Payments.</p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS may offer certain services for a fee (
                  <strong>“Premium Services”</strong>). By accessing or using
                  any Premium Services, you agree to pay all applicable fees as
                  specified at the time of purchase or subscription. Fees may
                  vary based on the specific Premium Service, usage level, or
                  subscription plan chosen. You are responsible for reviewing
                  the fees associated with any Premium Service before purchase
                  or use. Payment for Premium Services must be made using an
                  approved payment method. DUBS reserves the right to modify
                  fees for Premium Services at any time, with notice provided to
                  users as required by applicable law. Continued use of a
                  Premium Service after a fee change constitutes acceptance of
                  the new fee structure.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  You agree to provide and maintain current, complete, and
                  accurate purchase and account information for all transactions
                  made through the Service, including promptly updating your
                  account and other information (e.g., email address, payment
                  method details, etc.), to ensure that we can process your
                  transactions and contact you as needed. By placing an order,
                  you agree to pay all applicable charges, taxes, and other fees
                  at the current prices then in effect, and you authorize DUBS
                  to charge your chosen payment method.
                </p>
              </div>
              <div className="sub-heading">
                <p>6.2 Refunds.</p>
              </div>
              <div className="sub-data">
                <p>
                  Unless otherwise provided by law or by a particular Premium
                  Service offer, all purchases are final and non-refundable. To
                  request a refund, please contact our customer support team at{" "}
                  <a href="mailto:support@dubs.gg">
                    <span>support@dubs.gg</span>
                  </a>{" "}
                  with your order details and the reason for your refund
                  request.
                </p>
              </div>
              <div className="sub-heading">
                <p>6.3 Pricing Corrections and Order Cancellations.</p>
              </div>
              <div className="sub-data">
                <p>
                  We reserve the right to correct any errors or mistakes in
                  pricing, even if we have already requested or received
                  payment. In the event of a pricing error, DUBS reserves the
                  right to cancel any orders placed for products or services
                  listed at the incorrect price. While DUBS will make reasonable
                  efforts to process payments accurately, we are not responsible
                  for errors or delays caused by payment processors or financial
                  institutions. Refund and cancellation policies will be stated
                  at the time of purchase, and unless specified otherwise, all
                  sales are final.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>7. Third-Party Services</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  The Service may contain links to or integrate with third-party
                  websites, applications, games, bots, or other services
                  (“Third-Party Services”) that are not owned, operated, or
                  controlled by DUBS. Your use of Third-Party Services is
                  subject to their respective terms of service and privacy
                  policies, which we strongly advise you to review.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS explicitly disclaims any responsibility or liability for
                  Third-Party Services. We do not endorse, verify, or assume any
                  responsibility for the content, privacy policies, practices,
                  or availability of any Third-Party Services. You acknowledge
                  and agree that DUBS shall not be responsible or liable,
                  directly or indirectly, for any damage or loss caused or
                  alleged to be caused by or in connection with the use of or
                  reliance on any Third-Party Services.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  If you choose to link your DUBS account with a Third-Party
                  Service (such as a music streaming service, social media
                  platform, or gaming platform), we may receive and process
                  certain information from that service to enable integration
                  and enhance functionality. This information may include, but
                  is not limited to, your username, profile data, and usage
                  information from the third-party service. By linking your
                  account, you authorize us to access and use this information
                  in accordance with these Terms of Service and our{" "}
                  <a href="/help/privacypolicy">
                    <span>Privacy Policy</span>
                  </a>
                  . The information we receive from linked accounts will be used
                  solely for the purpose of providing and improving the
                  integrated services. Please note that the privacy practices of
                  Third-Party Services are governed by their respective privacy
                  policies. We recommend reviewing these policies before linking
                  your DUBS account. You may unlink your account from
                  Third-Party Services at any time through your DUBS account
                  settings. However, this will not delete any data that has
                  already been shared and processed.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS reserves the right to modify, suspend, or discontinue the
                  integration of any Third-Party Services within the Service at
                  any time without prior notice. We may also terminate or
                  restrict access to any Third-Party Services through the
                  Service at any time, without notice, for any reason deemed
                  appropriate by DUBS.
                </p>
              </div>
            </div>

            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>8. DISCLAIMERS AND LIMITATIONS</p>
            </div>
            <div className="sub-description">
              <div className="sub-heading">
                <p>8.1 Disclaimer of Warranties.</p>
              </div>
              <div className="sub-data">
                <p>
                  THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE.” DUBS, ITS
                  SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
                  AGENTS, PARTNERS, AND LICENSORS MAKE NO REPRESENTATIONS OR
                  WARRANTIES REGARDING THE SERVICE AND EXPRESSLY DISCLAIM ALL
                  WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                  NON-INFRINGEMENT. BY USING THE SERVICE, YOU ACKNOWLEDGE AND
                  AGREE THAT YOUR USE IS AT YOUR SOLE RISK.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS MAKES NO WARRANTY OR GUARANTEE THAT (A) THE SERVICE WILL
                  MEET YOUR REQUIREMENTS, (B) THE SERVICE WILL BE UNINTERRUPTED,
                  TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE
                  OBTAINED FROM USING THE SERVICE WILL BE ACCURATE OR RELIABLE,
                  OR (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR
                  OTHER MATERIAL PURCHASED OR OBTAINED THROUGH THE SERVICE WILL
                  MEET YOUR EXPECTATIONS.
                </p>
              </div>
              <div className="sub-heading">
                <p>8.2 Limitation of Liability.</p>
              </div>
              <div className="sub-data">
                <p>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DUBS, ITS
                  SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
                  AGENTS, PARTNERS, AND LICENSORS SHALL NOT BE LIABLE FOR ANY
                  INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR
                  EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR
                  LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE
                  LOSSES. THIS LIMITATION APPLIES REGARDLESS OF THE BASIS OF
                  LIABILITY (INCLUDING NEGLIGENCE), EVEN IF THE PARTIES HAVE
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND RELATES
                  TO (A) USE OF, OR INABILITY TO USE, THE SERVICE; (B) ANY
                  PRODUCTS, SERVICES, OR CONTENT OBTAINED THROUGH THE SERVICE;
                  (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS
                  OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE
                  SERVICE; OR (E) ANY OTHER MATTER RELATING TO THE SERVICE. SOME
                  JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  CERTAIN DAMAGES, SO THESE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                  DUBS’ TOTAL LIABILITY TO YOU FOR ANY AND ALL CLAIMS CAUSE
                  WHATSOEVER ARISING FROM OR RELATED TO THE USE OF THE SERVICE
                  SHALL BE LIMITED TO THE GREATER OF: (A) THE TOTAL AMOUNT YOU
                  PAID TO DUBS FOR THE SERVICE DURING THE 12 MONTHS IMMEDIATELY
                  PRECEDING THE EVENT GIVING RISE TO THE CLAIM; OR (B) ONE
                  HUNDRED U.S. DOLLARS ($100.00). THIS LIMITATION APPLIES
                  REGARDLESS OF THE CAUSE OF ACTION OR THE FORM OF THE CLAIM,
                  WHETHER IN CONTRACT, TORT, OR ANY OTHER LEGAL THEORY. THIS
                  LIMITATION OF LIABILITY IS AN ESSENTIAL PART OF THE BASIS OF
                  THE BARGAIN BETWEEN YOU AND DUBS AND SHALL APPLY EVEN IF ANY
                  EXCLUSIVE REMEDY PROVIDED FOR IN THESE TERMS OF SERVICE IS
                  FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                </p>
              </div>
              <div className="sub-heading">
                <p>8.3 Indemnification.</p>
              </div>
              <div className="sub-data">
                <p>
                  YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS DUBS, ITS
                  SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
                  AGENTS, PARTNERS, AND LICENSORS FROM AND AGAINST ANY AND ALL
                  CLAIMS, DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES, COSTS, OR
                  EXPENSES (INCLUDING, BUT NOT LIMITED TO, REASONABLE ATTORNEY’S
                  FEES) RESULTING FROM OR ARISING OUT OF: (A) YOUR USE OR ACCESS
                  OF THE SERVICE, BY YOU OR ANY PERSON USING YOUR ACCOUNT; (B) A
                  BREACH OF THESE TERMS OF SERVICE; OR (C) CONTENT (INCLUDING
                  USER CONTENT) POSTED ON THE SERVICE. THIS INDEMNIFICATION
                  OBLIGATION REQUIRES YOU TO DEFEND DUBS AGAINST SUCH CLAIMS AND
                  COVER ANY RESULTING COSTS OR DAMAGES.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>9. DISPUTE RESOLUTION</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  9.1 These Terms of Service and any disputes arising out of or
                  related to these Terms of Service or the Service shall be
                  governed in all respects by the laws of the State of Delaware,
                  without regard to its conflict of law provisions.
                </p>
              </div>
              <div className="strong">
                <p>
                  9.2 IMPORTANT NOTICE REGARDING DISPUTE RESOLUTION: THE
                  FOLLOWING SECTION CONTAINS PROVISIONS THAT AFFECT HOW LEGAL
                  DISAGREEMENTS BETWEEN YOU AND DUBS WILL BE RESOLVED. IT MAY
                  LIMIT YOUR ABILITY TO PURSUE LEGAL ACTION IN A TRADITIONAL
                  COURT SETTING. WE STRONGLY ADVISE YOU TO REVIEW THIS SECTION
                  THOROUGHLY TO UNDERSTAND ITS IMPLICATIONS FOR YOUR LEGAL
                  RIGHTS.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.3 Before initiating any formal dispute resolution process,
                  you agree to first contact DUBS and make a good faith effort
                  to resolve any disputes informally.
                </p>
              </div>

              <div className="sub-data">
                <p>
                  9.4 If informal resolution is unsuccessful, you agree to
                  participate in mandatory mediation with a mutually agreed-upon
                  mediator before pursuing any other form of dispute resolution.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.5 If mediation is unsuccessful, then you and DUBS agree that
                  any dispute, claim, or controversy arising out of or relating
                  to these Terms of Service or the breach, termination,
                  enforcement, interpretation, or validity thereof or the use of
                  the Service (collectively, <strong>“Disputes”</strong>) will
                  be settled by binding arbitration, except that each party
                  retains the right to seek injunctive or other equitable relief
                  in a court of competent jurisdiction to prevent the actual or
                  threatened infringement, misappropriation, or violation of a
                  party’s copyrights, trademarks, trade secrets, patents, or
                  other intellectual property rights. You acknowledge and agree
                  that you and DUBS are each waiving the right to a trial by
                  jury or to participate as a plaintiff or class member in any
                  purported class action or representative proceeding.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.6 The arbitration will be administered by the American
                  Arbitration Association (<strong>“AAA”</strong>) in accordance
                  with the Commercial Arbitration Rules and the Supplementary
                  Procedures for Consumer Related Disputes then in effect. The
                  arbitration will be conducted in New Castle County, Delaware,
                  unless you and DUBS agree otherwise. The arbitrator will have
                  exclusive authority to resolve any dispute relating to the
                  interpretation, applicability, or enforceability of this
                  binding arbitration agreement.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.7 YOU AGREE THAT ANY DISPUTE RESOLUTION PROCEEDINGS, WHETHER
                  IN ARBITRATION OR COURT, WILL BE CONDUCTED ONLY ON AN
                  INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION
                  OR AS A NAMED OR UNNAMED MEMBER IN A CLASS, CONSOLIDATED,
                  REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL LEGAL ACTION.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.8 You may opt out of this arbitration agreement by sending
                  written notice to DUBS within thirty (30) calendar days of
                  your first use of the Service. Your written notice must be
                  titled “Arbitration Opt-Out Notice” and mailed to OneXP LLC,
                  Attn: Legal Department, 1042 Princeton Drive, Suite B, Marina
                  Del Rey, Los Angeles, CA 90292, via certified U.S. Mail or by
                  Federal Express (or an equivalent international courier
                  service). For such notice to be valid, it must be sent by the
                  user wishing to opt out (or their parent or legal guardian if
                  the user is a minor) and include: the user’s full legal name,
                  DUBS username, email address, a clear statement that the user
                  wishes to opt out of arbitration, and the user’s signature (or
                  the user’s parent’s or legal guardian’s signature if the user
                  is a minor). If you do not opt out within the specified
                  timeframe, fail to provide all required information, or
                  otherwise fail to follow these opt-out instructions, then you
                  shall be bound to arbitrate disputes in accordance with these
                  Terms of Service. Opting out is mutual, and therefore should
                  you choose to opt out, then DUBS will also not be bound by
                  these arbitration provisions. Your decision to opt out does
                  not and will not affect any other parts of this Terms of
                  Service, nor does it revoke or otherwise affect any previous
                  acceptance of the arbitration agreement.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.9 Notwithstanding the arbitration agreement, you and DUBS
                  retain the right to pursue qualifying claims in the small
                  claims court of New Castle County, Delaware. Furthermore,
                  disputes related to intellectual property rights (including,
                  but not limited to, patents, copyrights, moral rights,
                  trademarks, and trade secrets), as well as claims involving
                  alleged piracy or unauthorized use of the Service, are exempt
                  from the arbitration requirement and may be litigated in
                  appropriate courts. Furthermore, this arbitration agreement
                  does not preclude you from bringing issues to the attention of
                  federal, state, or local agencies.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.10 YOU AND DUBS AGREE THAT ANY CAUSE OF ACTION ARISING OUT
                  OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1)
                  YEAR AFTER THE CAUSE OF ACTION ARISES OR IT IS PERMANENTLY
                  BARRED.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  9.11 DUBS will provide at least 30 days’ notice before making
                  any material changes to the dispute resolution provisions of
                  these Terms of Service. Such changes will apply only to
                  disputes or claims arising after the 30-day notice period. If
                  you do not agree to the changes, you may either terminate your
                  account or opt-out, as outlined in Section 9.8, within the
                  30-day notice period. Continued use of the Service after the
                  30-day notice period will be deemed to constitute acceptance
                  of the changes.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>10. TERMINATION</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  We reserve the right to terminate or suspend your account at
                  our sole discretion, with or without notice, for any reason,
                  including, but not limited to: violations of these Terms of
                  Service and our other applicable policies and guidelines;
                  compliance with legal requirements or court orders; prevention
                  of harm to you, us, other users, or third parties; prolonged
                  inactivity exceeding two (2) years; or if we believe your
                  continued use of the Service poses a risk to DUBS, other
                  users, or third parties.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  Upon termination, your right to use the Service ceases
                  immediately. We are not liable for any loss or damage that may
                  result from termination of your access to the Service. All
                  provisions of these Terms of Service which by their nature
                  should survive termination shall survive, including, without
                  limitation, ownership provisions, warranty disclaimers,
                  indemnity, and limitations of liability.
                </p>
              </div>
            </div>

            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>11. GENERAL PROVISIONS </p>
            </div>
            <div className="sub-description">
              <div className="sub-heading">
                <p>11.1 Entire Agreement.</p>
              </div>
              <div className="sub-data">
                <p>
                  These Terms of Service, including any legal notices,
                  disclaimers, and policies incorporated by reference and
                  displayed on the Service, shall constitute the entire
                  agreement between you and DUBS regarding your use of the
                  Service. These Terms of Service supersede all prior or
                  contemporaneous communications, proposals, and understandings
                  between you and DUBS with respect to the Service.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.2 Amendments.</p>
              </div>
              <div className="sub-data">
                <p>
                  We reserve the right to modify or replace these Terms of
                  Service at any time at our sole discretion. For significant
                  changes to the Terms of Service, we will endeavor to provide
                  reasonable notice before the new terms take effect. Notice
                  will be communicated through our website, email, or other
                  prominent channels.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.3 Waiver.</p>
              </div>
              <div className="sub-data">
                <p>
                  No waiver by DUBS of any provision of these Terms of Service
                  shall be deemed a waiver of any other provision or a
                  continuing waiver of such provision. DUBS’ failure to exercise
                  or enforce any right, power, or remedy under these Terms of
                  Service does not and shall not constitute a waiver of any such
                  right, power, or remedy.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.4 Severability.</p>
              </div>
              <div className="sub-data">
                <p>
                  If any provision of these Terms of Service is held to be
                  invalid, illegal, or otherwise unenforceable by a court of
                  competent jurisdiction, then such provision shall be modified
                  to the minimum extent necessary to make it enforceable while
                  preserving its intent, or, if not possible, then such
                  provision shall be severed from these Terms of Service, while
                  the remaining provisions of these Terms of Service shall
                  remain in full force and effect.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.5 Assignment.</p>
              </div>
              <div className="sub-data">
                <p>
                  You may not assign, sublicense, or transfer these Terms of
                  Service or any rights and licenses granted to you hereunder,
                  nor delegate any of your obligations, to any third party
                  without DUBS’ prior written consent. Any attempted assignment,
                  sublicense, transfer, or delegation without such consent is
                  void and constitutes a material breach of these Terms of
                  Service. DUBS may freely assign or transfer these Terms of
                  Service, in whole or in part, to any third party, including
                  but not limited to its affiliates, without restriction.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.6 Relationship of Parties.</p>
              </div>
              <div className="sub-data">
                <p>
                  Your use of the Service does not create any agency,
                  partnership, joint venture, or employment relationship with
                  DUBS. You have no authority to bind, represent, or act on
                  behalf of DUBS in any capacity, and you may not create or
                  assume any obligation or liability of any kind or nature on
                  behalf of DUBS.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.7 No Third-Party Beneficiaries.</p>
              </div>
              <div className="sub-data">
                <p>
                  This Terms of Service is intended solely for the benefit of
                  the parties hereto. Except as otherwise expressly set forth in
                  this Terms of Service, no provision of this Terms of Service
                  shall create or be deemed to create any rights, benefits, or
                  causes of action for any third parties
                </p>
              </div>
              <div className="sub-heading">
                <p>11.8 Force Majeure.</p>
              </div>
              <div className="sub-data">
                <p>
                  DUBS shall not be liable for any delay or failure to perform
                  resulting from any cause beyond its reasonable control,
                  including, but not limited to, acts of God, pandemics, war,
                  terrorism, riots, embargoes, acts of civil or military
                  authorities, fire, floods, accidents, strikes or shortages of
                  transportation facilities, fuel, energy, labor, or materials.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.9 Notices.</p>
              </div>
              <div className="sub-data">
                <p>
                  You acknowledge and agree that we may provide you with
                  notices, including, but not limited to, those regarding any
                  changes to these Terms of Service, by email, regular mail, or
                  postings on the Service. Notice will be deemed given
                  twenty-four hours after email is sent, unless we are notified
                  that the email address is invalid. Alternatively, we may give
                  you legal notice by mail to a postal address, if provided by
                  you through the Service. In such case, notice will be deemed
                  given three days after the date of mailing.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.10 Electronic Communications.</p>
              </div>
              <div className="sub-data">
                <p>
                  When you use the Service or send emails to us, you are
                  communicating with us electronically. You consent to receive
                  communications from us electronically. We will communicate
                  with you by email or by posting notices on the Service. You
                  agree that all agreements, notices, disclosures and other
                  communications that we provide to you electronically satisfy
                  any legal requirement that such communications be in writing.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.11 Interpretation.</p>
              </div>
              <div className="sub-data">
                <p>
                  The section headings in these Terms of Service are for
                  convenience of reference only and have no legal or contractual
                  effect. The word “including” means “including without
                  limitation.” Any use of the singular shall be deemed to
                  include the plural, and vice versa.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.12 Export Control.</p>
              </div>
              <div className="sub-data">
                <p>
                  You agree to use the Service in compliance with all applicable
                  United States export control laws and regulations. You shall
                  not use, export, or re-export the Service except as authorized
                  by U.S. law and the laws of the jurisdiction where you
                  obtained the Service. Without limiting the foregoing, you
                  specifically agree not to export or re-export the Service to
                  any country subject to U.S. embargo or trade sanctions, to any
                  person or entity on the U.S. Treasury Department’s list of
                  Specially Designated Nationals, to any person or entity on the
                  U.S. Department of Commerce’s Denied Persons List or Entity
                  List, or to any person or entity where such export or
                  re-export is prohibited by applicable U.S. law.
                </p>
              </div>
              <div className="sub-data">
                <p>
                  By using the Service, you represent and warrant that you are
                  not located in a country subject to a U.S. Government embargo
                  or designated by the U.S. Government as a
                  “terrorist-supporting” country, that you are not listed on any
                  U.S. Government list of prohibited or restricted parties, and
                  that you will comply with all applicable U.S. and
                  international export control and trade sanctions laws and
                  regulations. You acknowledge that the lists of prohibited
                  countries, persons, and entities may change over time, and you
                  agree to stay informed of and comply with any such updates.
                  Any violation of these export control provisions may result in
                  immediate termination of your right to use the Service and may
                  subject you to legal consequences under applicable laws.
                </p>
              </div>
              <div className="sub-heading">
                <p>11.13 Mobile and Data Usage Charges.</p>
              </div>
              <div className="sub-data">
                <p>
                  Please be aware that your use of the Service may result in
                  charges from your mobile service provider or internet service
                  provider. These expenses may include, but are not limited to,
                  data usage fees, text messaging costs, and other charges
                  related to mobile or internet connectivity. It is your
                  responsibility to understand and pay for any such charges
                  incurred while using the Service. We recommend checking with
                  your service provider for details about potential fees
                  associated with your plan
                </p>
              </div>
              <div className="sub-heading">
                <p>11.14 Survival.</p>
              </div>
              <div className="sub-data">
                <p>
                  The following provisions shall survive any termination or
                  expiration of these Terms of Service or your use of the
                  Service: Intellectual Property Rights, User Content,
                  Indemnification, Limitation of Liability, Disclaimer, Dispute
                  Resolution, and any other provisions that, by their nature or
                  intent, are intended to survive such termination or
                  expiration.
                </p>
              </div>
            </div>
            <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
              <p>12. CONTACT INFORMATION</p>
            </div>
            <div className="sub-description">
              <div className="sub-data">
                <p>
                  If you have any questions about these Terms of Service, please
                  contact us at{" "}
                  <a href="mailto:support@dubs.gg">
                    <span>support@dubs.gg</span>
                  </a>
                  .
                </p>
              </div>
              <div className="sub-data">
                <p>
                  By using the Service, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms of Service.
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </body>
    </>
  );
};
export default Terms;
