import "../../Styles/Privacy.css";
import Footer from "./Footer";
const Privacy = () => {
  return (
    <>
      <div className="privacy-body">
        <div class="dubslogo-header grid lg:grid-cols-3 gap-0 lg:gap-4 py-2 lg:py-4 px-14">
          <div class="dubs-logo mt-2 flex lg:block justify-center">
            <img
              className="h-12"
              src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
            />
          </div>
          <div class="header">
            <h1 className="text-center">PRIVACY POLICY</h1>
          </div>
        </div>
        {/* <div className='dubslogo-header  flex flex-col justify-center'> */}
        {/* <div className=' mt-[1rem] mb-[2rem] dubs-web-logo'>
          <div className='flex   w-full ml-[2rem] mt-[1rem]'>
       <img src="/images/Dubs-web-logo.png"/>

       <div className='flex w-full item-center justify-center'>
       <h1 className='ml-[-15rem]'>PRIVACY POLICY</h1>
       </div>
      
        </div>
        </div> */}
        {/* <div className='dubs-mobile-logo'>
                <div className='logo-header mt-5 flex justify-center'>
                    <img src="/images/dubslogo.svg" />
                </div>
                <div className='header flex mt-1  justify-center'>
                    <h1>PRIVACY POLICY</h1>

                </div>
                </div>
            </div> */}
        <div className="Privacy-page mt-[2rem] mb-[2rem] mx-3 lg:mx-5">
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>1. INTRODUCTIONS</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                Welcome to DUBS.GG, provided by OneXP LLC, its subsidiaries, and
                affiliated companies (collectively “<strong>DUBS</strong>,” “
                <strong>we</strong>,” “<strong>us</strong>,” or “
                <strong>our</strong>”). This Privacy Policy is designed to help
                you understand how we collect, use, store, and protect your
                personal information in connection with DUBS’ products,
                websites, applications, features, content, and all related
                services and technologies (collectively, the “
                <strong>Services</strong>”).
              </p>
            </div>
            <div className="sub-data">
              <p>
                Please read this Privacy Policy carefully. By accessing or using
                the Service, you acknowledge that you have read, understood, and
                agree to the practices described in this Privacy Policy and to
                be bound by our{" "}
                <a href="/help/termsofuse">
                  <span>Terms of Service</span>
                </a>
                . If you do not agree to this Privacy Policy in its entirety,
                then you must not access or use the Service.
              </p>
            </div>
            <div className="sub-data">
              <p>
                We reserve the right to modify this Privacy Policy at any time
                at our sole discretion. For significant changes to the Privacy
                Policy, we will endeavor to provide reasonable notice before the
                new terms take effect. Notice will be communicated through our
                website, email, or other prominent channels. We retain sole
                discretion in determining what constitutes a material change.
                For minor changes, we may update the Privacy Policy without
                prior notice.
              </p>
            </div>
            <div className="sub-data">
              <p>
                We recommend that you regularly review this Privacy Policy to
                stay informed of any changes. Your continued use of the Service
                after any revisions become effective constitutes your acceptance
                of and agreement to be bound by the updated terms. If you do not
                agree to the new terms, you must discontinue using the Service.
              </p>
            </div>
          </div>

          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>2. INFORMATION WE COLLECT</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                We collect information in three main ways: directly from you,
                automatically through your use of our Service, and from third
                parties.
              </p>
            </div>
            <div className="sub-heading">
              <p>2.1 Information You Provide.</p>
            </div>
            <div className="sub-data">
              <p>
                This includes account information (username, email, date of
                birth), content you create (messages, posts, images, videos,
                audio recordings, voice communications), communications with our
                support team, survey responses, and payment details for
                purchases. We may also collect:
              </p>
            </div>
            <div className="sub-data">
              <ul>
                <li>Demographic data, such as your country of residence</li>
                <li>
                  Profile data, including links to your social media profiles
                  and information about your participation in our contests or
                  promotions
                </li>
                <li>
                  Transactional data related to your activities in connection
                  with the Service
                </li>
                <li>
                  Marketing preferences and your engagement with our marketing
                  communications
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>2.2 Information We Collect Automatically.</p>
            </div>
            <div className="sub-data">
              <p>
                We gather data about your activities and interactions with our
                Service, including content engagement, profile views, and
                feature usage. We also collect device and log information (IP
                address, browser type, operating system), and may infer your
                approximate location based on your IP address. Additionally, we
                may collect:
              </p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  Detailed device information, including screen resolution, RAM
                  and storage capacity, CPU usage, and unique identifiers used
                  for advertising purposes
                </li>
                <li>
                  Online activity data, including navigation paths, time spent
                  on pages, and interaction with our emails
                </li>
                <li>
                  Communication interaction data, such as whether you open or
                  forward our emails
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>2.3 Information from Third Parties.</p>
            </div>
            <div className="sub-data">
              <p>
                We may receive information about you from other users, partners,
                or social media platforms (if you choose to link your account).
                This can include contact data, demographic information, profile
                data, payment information, communications data, transactional
                data, marketing preferences, and promotion-related information.
                We may also obtain data from:
              </p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  Public sources, such as public records and social media
                  platforms
                </li>
                <li>Data providers and licensors</li>
                <li>Marketing partners and event co-sponsors</li>
                <li>Service providers that assist in operating our Service</li>
              </ul>
            </div>
            <div className="sub-data">
              <p>
                Our use of information received from third-party APIs, such as
                Google, adheres to their respective developer policies and user
                data policies.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>3. HOW WE USE YOUR INFORMATION</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                <strong>3.1</strong> We use your information to provide,
                improve, and personalize our Service, as well as to ensure its
                security and comply with legal obligation, including in
                connection with the following:
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                <strong>Service Delivery and Improvement.</strong> Operating,
                maintaining, and improving our Service, developing new features,
                analyzing user interactions to improve your experience,
                customizing content, remembering your preferences, and providing
                customer support.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                <strong>Communication.</strong> Responding to your inquiries,
                providing support, sending important Service updates, and, with
                your consent (where required by law), we may also send marketing
                communications.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(c)</p>
              <p>
                <strong>Safety and Security.</strong> Detecting and preventing
                technical issues, protecting against fraudulent or illegal
                activity, and enforcing our{" "}
                <a href="/help/termsofuse">
                  <span>Terms of Service</span>
                </a>{" "}
                and other agreements, policies, and guidelines.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(d)</p>
              <p>
                <strong>Transaction Processing.</strong> Processing payments for
                purchases made through the Service and sending related
                transaction information.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(e)</p>
              <p>
                <strong>Legal Compliance.</strong> Complying with applicable
                laws, regulations, and legal processes, including responding to
                lawful requests from public authorities.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(f)</p>
              <p>
                <strong>Analytics and Marketing.</strong> Analyzing Service
                usage to improve our offerings, using your information for
                marketing purposes, including interest-based advertising (you
                can opt-out of marketing communications as described herein).
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(g)</p>
              <p>
                <strong>Events and Promotions.</strong> Administering contests,
                sweepstakes, and other promotional events.
              </p>
            </div>
            <div className="sub-heading">
              <p>3.2 Additional Data Usage.</p>
            </div>
            <div className="sub-data">
              <p>
                We may also create aggregated, de-identified, or anonymized data
                for our legitimate business purposes. This derived data does not
                identify you as an individual and may be used for analytics,
                research, improving our services, and other business purposes.
              </p>
            </div>
            <div className="sub-heading">
              <p>3.3 Legal Bases for Processing.</p>
            </div>
            <div className="sub-data">
              <p>
                We process your information based on one or more of the
                following legal grounds:
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                to perform our contractual obligations or take pre-contractual
                steps at your request;{" "}
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                to comply with applicable laws, regulations, and legal
                processes;{" "}
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(c)</p>
              <p>
                to pursue our legitimate business interests, such as improving
                our services and ensuring security;{" "}
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(d)</p>
              <p>
                with your explicit consent, where required by law for certain
                types of processing; and
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(e)</p>
              <p>in rare cases, to protect vital interests.</p>
            </div>
            <div className="sub-heading">
              <p>3.4 Information About Others.</p>
            </div>
            <div className="sub-data">
              <p>
                When you use features to invite friends or contacts to the
                Service, we may collect and process personal data about these
                individuals, such as names, email addresses, and phone numbers.
                We require that you obtain explicit consent from these
                individuals before sharing their information with us. By using
                our invitation features, you confirm that you have the right to
                share the provided contact information and that you have
                informed the individuals that their data will be processed in
                accordance with our privacy policy.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>4. HOW WE SHARE YOUR INFORMATION</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                4.1 We may share information in the following circumstances to
                operate the Service and our business effectively:
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                <strong>Other Users.</strong> Your public profile and content
                are visible to other users based on your privacy settings and
                how you use the Service. You can control some of this through
                your account settings.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                <strong> Public Information.</strong> Certain information, such
                as your profile and user-generated content, may be visible to
                other users and the public. Please be aware that this
                information can be seen, collected, and used by others, we
                cannot control how others may use this information, you should
                exercise caution when deciding what information to make public,
                and we are not responsible for the use of such information by
                third parties.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(c)</p>
              <p>
                <strong>Service Providers and Business Partners.</strong> We
                share information with third-parties who assist in operating our
                business and providing the Service. This includes hosting
                providers, payment processors, advertising partners, analytics
                providers, and professional advisors (e.g., lawyers,
                accountants, auditors, etc.). These entities are contractually
                obligated to use your information only as necessary to provide
                their services.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(d)</p>
              <p>
                <strong>Legal Compliance and Protection.</strong> We may
                disclose information in response to legal requests (such as
                court orders or subpoenas), to prevent, investigate, or take
                action regarding illegal activities, suspected fraud, or
                potential threats or harm, to establish or exercise our legal
                rights or defend against legal claims, and to comply with
                applicable laws and regulations.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(e)</p>
              <p>
                <strong>Business Transfers.</strong> In the event of a business
                transaction (such as a merger, acquisition, sale of assets,
                etc.), your information may be transferred as part of that
                transaction.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(f)</p>
              <p>
                <strong>With Your Consent.</strong> We may share your
                information in other ways if you have given us specific consent
                or direction to do so.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(g)</p>
              <p>
                <strong>Aggregated or De-identified Data.</strong> We may share
                aggregated or de-identified information that cannot reasonably
                be used to identify you with third parties for research,
                marketing, analytics, or other purposes.
              </p>
            </div>
            <div className="sub-heading">
              <p>4.2 Important Notes.</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  We do not sell your personal information to third parties.
                </li>
              </ul>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  We strive to ensure that your information is shared
                  responsibly and in accordance with this Privacy Policy and any
                  other applicable notices provided at the time of collection.
                </li>
              </ul>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>5. Cookies and Similar Technologies</p>
          </div>
          <div className="sub-description">
            <div className="sub-heading">
              <p>5.1 What Are Cookies and Why We Use Them.</p>
            </div>
            <div className="sub-data">
              <p>
                Cookies are small text files placed on your device when you
                visit our Service. We use both first-party (set by DUBS) and
                third-party (set by our partners and service providers) cookies
                to make our Service function efficiently, provide information to
                website owners, and enhance your experience.
              </p>
            </div>
            <div className="sub-heading">
              <p>5.2 Types and Purposes of Cookies.</p>
            </div>
            <div className="sub-data">
              <p>We employ four main types of cookies:</p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                <strong>Essential Cookies.</strong> Necessary for the Service to
                function properly. These cannot be disabled.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                <strong>Functional Cookies.</strong> Enable specific features
                and remember your preferences.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(c)</p>
              <p>
                <strong>Analytic and Performance Cookies.</strong> Help us
                understand how visitors interact with our Service.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(d)</p>
              <p>
                <strong>Targeting Cookies.</strong> Used to deliver personalized
                advertising and marketing.
              </p>
            </div>
            <div className="sub-data">
              <p>
                These cookies serve various purposes, including maintaining and
                improving our Service, personalizing your experience, analyzing
                traffic patterns, enabling social media features, and delivering
                targeted advertising.
              </p>
            </div>
            <div className="sub-heading">
              <p>5.3 Similar Technologies.</p>
            </div>
            <div className="sub-data">
              <p>
                In addition to cookies, we use other tracking technologies such
                as web beacons, pixel tags, and local storage objects. These
                technologies function similarly to cookies and are used for
                comparable purposes.
              </p>
            </div>
            <div className="sub-heading">
              <p>5.4 Managing Your Cookie Preferences.</p>
            </div>
            <div className="sub-data">
              <p>
                You can control and/or delete cookies as you wish. You can
                delete all cookies that are already on your device and you can
                set most browsers to prevent them from being placed. However, if
                you do this, you may have to manually adjust some preferences
                every time you visit a site and some services and
                functionalities may not work.
              </p>
            </div>
            <div className="sub-heading">
              <p>To modify your cookie settings:</p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                Access our cookie preferences page at:{" "}
                <a href="/cookie-preferences">
                  <span>https://dubs.gg/cookie-preferences</span>
                </a>
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                Use your browser settings to control cookies. Here are links to
                cookie management instructions for common browsers:
              </p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  Google Chrome -{" "}
                  <a
                    href="https://support.google.com/chrome/answer/95647"
                    target="_blank"
                  >
                    <span>https://support.google.com/chrome/answer/95647</span>
                  </a>
                </li>
                <li>
                  Firefox -{" "}
                  <a
                    href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox"
                    target="_blank"
                  >
                    <span>
                      https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox
                    </span>
                  </a>
                </li>
                <li>
                  Safari -{" "}
                  <a
                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                  >
                    {" "}
                    <span>
                      https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                    </span>
                  </a>
                </li>
                <li>
                  Internet Explorer -{" "}
                  <a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                  >
                    <span>
                      https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="sub-data">
              <p>(c) Use third-party tools:</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  AdChoices -{" "}
                  <a href=" https://youradchoices.com/" target="_blank">
                    <span>https://youradchoices.com</span>
                  </a>
                </li>
                <li>
                  Network Advertising Initiative -{" "}
                  <a
                    href="https://www.networkadvertising.org/choices"
                    target="_blank"
                  >
                    <span>https://www.networkadvertising.org/choices</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>5.5 Do Not Track.</p>
            </div>
            <div className="sub-data">
              <p>
                Some browsers have a “Do Not Track” feature that lets you tell
                websites that you do not want to have your online activities
                tracked. At this time, we do not respond to browser “Do Not
                Track” signals.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>6. Your Privacy Rights and Choices</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                We respect your privacy rights and offer several ways for you to
                control your personal information.
              </p>
            </div>
            <div className="sub-heading">
              <p>6.1 General Rights and How to Exercise Them.</p>
            </div>
            <div className="sub-data">
              <p>
                All users, regardless of location, have the right to access and
                update their account information, delete their account and
                associated data, opt out of marketing communications, and
                control cookie preferences. To exercise these rights, you can
                either email us at{" "}
                <a href="mailto:privacy@dubs.gg">
                  {" "}
                  <span>privacy@dubs.gg</span>
                </a>{" "}
                or use the privacy controls within your account settings. We
                will respond to your request within the timeframe required by
                applicable law, generally within 30 days.
              </p>
            </div>
            <div className="sub-data">
              <p>
                To protect your privacy, we may need to verify your identity
                before processing your request. We may require additional
                information for verification purposes. You may designate an
                authorized agent to make a request on your behalf, provided they
                submit proof of your written permission.
              </p>
            </div>
            <div className="sub-heading">
              <p>6.2 Limitations</p>
            </div>
            <div className="sub-data">
              <p>
                We may not be able to fulfill your request if we cannot verify
                your identity, if your request infringes on the rights of
                others, if the request is manifestly unfounded or excessive, or
                if the information is subject to legal retention requirements.
                If we decline to take action on your request, we will inform you
                of the reasons and how you can challenge our decision.
              </p>
            </div>
            <div className="sub-heading">
              <p>6.3 Your Choices</p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(a)</p>
              <p>
                <strong>Account Information.</strong> You can review and update
                certain account information by logging into your account.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(b)</p>
              <p>
                <strong>Marketing Communications.</strong> You can opt out of
                marketing-related emails by following the unsubscribe
                instructions in the email or by contacting us. Note that you may
                continue to receive service-related and other non-marketing
                emails.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(c)</p>
              <p>
                <strong>Cookies and Tracking Technologies.</strong> Most
                browsers allow you to remove or reject cookies. You can also
                configure your device to prevent images from loading to block
                web beacons. For more information about managing cookies, visit
                &nbsp;
                <a href="https://allaboutcookies.org/" target="_blank">
                  <span>www.allaboutcookies.org.</span>
                </a>
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(d)</p>
              <p>
                <strong>Advertising Choices.</strong> You can limit the use of
                your information for interest-based advertising through various
                methods, including:
              </p>
            </div>
            <div className="sub-data">
              <ul>
                <li>Changing browser settings to block third-party cookies</li>
                <li>Using privacy browsers or ad-blocking browser plug-ins</li>
                <li>
                  Adjusting platform settings on services like Google and
                  Facebook
                </li>
                <li>
                  Using ad industry opt-out tools provided by organizations like
                  the Network Advertising Initiative and Digital Advertising
                  Alliance
                </li>
                <li>
                  Adjusting mobile device settings to limit the use of
                  advertising IDs
                </li>
              </ul>
            </div>
            <div className="sub-data">
              <p>
                Please note that you’ll need to apply these opt-out settings on
                each device and browser you use. We cannot guarantee that all
                companies we work with participate in the opt-out programs
                mentioned.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(e)</p>
              <p>
                <strong>Information Provision.</strong> Some services require
                certain personal information. If you choose not to provide
                required information, we may not be able to offer those
                services.
              </p>
            </div>
            <div className="sub-data flex gap-2">
              <p>(f)</p>
              <p>
                <strong>Third-Party Platforms.</strong> If you connect to our
                service through a social media or other third-party platform,
                you may be able to limit the information we receive through your
                account settings on that platform.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>7. Data Security</p>
          </div>
          <div className="sub-description">
            <div className="sub-heading">
              <p>7.1 Our Commitment to Security.</p>
            </div>
            <div className="sub-data">
              <p>
                DUBS takes the security of your personal information seriously,
                employing industry-standard technical, administrative, and
                physical safeguards designed to protect the personal information
                we collect. However, it’s important to note that no method of
                Internet transmission or electronic storage is 100% secure, and
                we cannot guarantee security of your information.
              </p>
            </div>
            <div className="sub-heading">
              <p>7.2 Your Role in Data Security.</p>
            </div>
            <div className="sub-data">
              <p>
                While we implement protective measures to protect your
                information, the security of your information also depends on
                you. We strongly encourage you to use strong, unique passwords
                for your Service account, be cautious about sharing personal
                information in public areas of the Service, and keep your
                account credentials confidential and do not share them with
                others.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>8. Data Retention</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                We retain your personal information for as long as necessary to
                fulfill the purposes we collected it for, including maintaining
                your account, providing our services, legal compliance, dispute
                resolution, and enforcing our agreements. Retention periods are
                based on a variety of factors, including quantity, nature, and
                sensitivity of the information, potential risks of unauthorized
                use or disclosure, the purposes for processing, and legal
                requirements. We may retain data longer if litigation or a
                complaint is reasonably anticipated. Once the retention period
                ends, we securely delete or anonymize information and we may
                continue to retain aggregated, anonymized information
                indefinitely.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>9. Children’s Privacy and Age Restrictions</p>
          </div>
          <div className="sub-description">
            <div className="sub-heading">
              <p>9.1 Age Requirements</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  Our Service is intended for users who are 13 years of age or
                  older.
                </li>
                <li>
                  Users between the ages of 13 and 18 should only use the
                  Service with the explicit involvement and approval of a parent
                  or guardian.
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>9.2 No Collection of Children’s Data</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  We do not knowingly collect, use, or disclose personal
                  information from children under the age of 13.
                </li>
                <li>
                  If we learn that we have collected personal information from a
                  child under 13, we will promptly delete that information.
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>9.3 Parental Consent and Rights</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  If you are a parent or guardian and you believe your child
                  under 13 has provided us with personal information, please
                  contact us at{" "}
                  <a href="mailto:privacy@dubs.gg">
                    <span> privacy@dubs.gg</span>
                  </a>
                  .
                </li>
                <li>
                  Parents or guardians of users aged 13-17 can contact us to
                  request review, deletion, or restrictions on the processing of
                  their teen’s personal information.
                </li>
                <li>
                  We will work with you to ensure that such information is
                  deleted from our records or appropriately managed.
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>9.4 Teen Privacy (Ages 13-17).</p>
            </div>
            <div className="sub-data">
              <p>For users between 13 and 17 years old:</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  We strongly encourage you to discuss the use of the Service
                  with your parents or guardians.
                </li>
                <li>
                  Some features may be restricted or require parental consent.
                </li>
                <li>
                  We may use age-gating techniques to restrict access to certain
                  features for users under 18.
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>9.5 Age Verification.</p>
            </div>
            <div className="sub-data">
              <ul>
                <li>
                  We reserve the right to request proof of age at any point to
                  ensure compliance with this policy.
                </li>
                <li>
                  Users may be required to verify their age when accessing
                  certain features or content.
                </li>
              </ul>
            </div>
            <div className="sub-heading">
              <p>9.6 Reporting Underage Users.</p>
            </div>
            <div className="sub-data">
              <p>
                If you believe someone under the age of 13 is using our Service,
                please report this to us immediately at
                <a href="mailto:privacy@dubs.gg">
                  <span> privacy@dubs.gg</span>
                </a>
                .
              </p>
            </div>
            <div className="sub-heading">
              <p>
                9.7 Third-Party Services for Parental Consent and Notification.
              </p>
            </div>
            <div className="sub-data">
              <p>
                While DUBS operates and provides the Services, we may engage
                trusted third-party service providers to assist with certain
                functionalities related to parental consent and notification.
                These services help us obtain verified parental consent where
                required and informing parents or legal guardians about their
                teen’s online activities. We carefully select these providers
                based on their expertise in handling age-appropriate
                registrations, their parental management tools, and their
                compliance with relevant privacy laws and standards. These
                third-party services have their own methods for collecting,
                using, and protecting personal information, so we strongly
                recommend reviewing their respective privacy policies for
                specific details about their practices.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>10. International Data Transfers</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                Our primary operations are based in the United States, however,
                we collaborate with service providers globally, which may
                involve transferring and processing your personal data in
                various countries. These jurisdictions may have data protection
                laws that differ from those in your country of residence,
                potentially offering less stringent protections. By using our
                Service, you consent to the transfer, storage, and processing of
                your personal data in these countries, including the United
                States and other regions.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>11. Third-Party Websites and Services</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                Our Service may contain links to or integrate content from
                third-party websites, applications, or other services that are
                not owned or controlled by DUBS. We are not responsible for the
                privacy practices or content of these third-party services, and
                the presence of links or integrated content does not imply our
                endorsement or affiliation. When you leave our Service or
                interact with third-party content, you may be subject to their
                privacy policies and terms. We strongly encourage you to review
                the privacy policies of any third-party services you access
                through our Service or that collect your personal information.
                DUBS cannot control and is not liable for how these third
                parties collect, use, or share your information. By using our
                Service, you acknowledge and accept these conditions regarding
                third-party websites and services.
              </p>
            </div>
          </div>
          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>12. Changes to This Privacy Policy</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                We reserve the right to modify this Privacy Policy at any time.
                If we make material changes, we will notify you by posting a
                notice on our website or sending you an email. The date of the
                latest revision will be indicated at the top of the Privacy
                Policy. Unless stated otherwise, any modifications will become
                effective immediately upon posting the updated version. Your
                continued use of the Service after any changes signifies your
                acceptance of the updated Privacy Policy. We encourage you to
                review this Privacy Policy regularly to stay informed about the
                policies that apply to your information.
              </p>
            </div>
          </div>

          <div className="heading lg:ml-[2rem] lg:mt-[1.5rem]">
            <p>13. Contact Us</p>
          </div>
          <div className="sub-description">
            <div className="sub-data">
              <p>
                If you have any questions about this Privacy Policy or our data
                practices, please contact us at:
              </p>
            </div>
            <div className="sub-data">
              <div className="flex gap-4">
                <p>Email:</p>
                <p className="ml-6">
                  <a href="mailto:privacy@dubs.gg">
                    <span>privacy@dubs.gg</span>
                  </a>
                </p>
              </div>
              <div className="flex gap-4">
                <p>Address:</p>
                <p className="ml-2">
                  OneXP LLC
                  <br />
                  1042 Princeton Drive <br /> Suite B <br />
                  Marina Del Rey <br /> Los Angeles, CA 90292
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Privacy;
