import "../../Styles/landingpage.css";
const Landingpage = () => {
  return (
    <>
      <body id="Landing-page">
        <div className="Landing-page">
          <img
            className="parental-background"
            src=" /images/parental_background.png"
          />
          <div className="dubslogo-header  flex flex-col justify-center">
            <div className="dubs-mobile-logo">
              <div className="logo-header mt-5 flex justify-center">
                <img src="/images/dubslogo.svg" />
              </div>
            </div>
            <div className="items-center flex flex-col text-center justify-center main-conatiner">
              <div className="image-part">
                <img className="z-10" src="/images/parental_img.png"></img>
              </div>

              <div className="content">
                <h1 className="header">Thank you</h1>
                <p>
                  We’ve successfully confirmed that you’re an adult. Your child
                  now has the necessary permission to access{" "}
                  <span className="strong">DUBS.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};
export default Landingpage;
