import { useDebugValue, useEffect, useRef, useState } from "react";
import "../../Styles/footer.css";
import { sendEmail } from "../../apiServices/apiService";

const FooterMain = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [modal, setModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const popupRef = useRef(null);
  const contactRef = useRef(null);

  // document.addEventListener('DOMContentLoaded', function() {
  //   // Initialize the modal here
  //   const modalElement = document.getElementById('hs-scale-animation-modal');
  //   // Add any library-specific initialization code if required
  // });

  // const modalOptions = () => {
  // setModal(!modal)
  // }

  const handleChange = (e) => {
    let { id, value } = e.target;
    if (id === "email") {
      setEmail(value);
    } else if (id === "message") {
      setMessage(value);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && emailRegex.test(email);
  };

  const validateDesc = (d) => {
    const nonSpaceCharacters = d.replace(/\s/g, "");
    return nonSpaceCharacters.length >= 5;
  };

  const sendEmailFunction = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!validateDesc(message)) {
      newErrors.message = "Description must be 5 or max 500 characters.";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSending(false);
    } else {
      setErrors({});
      let obj = {
        email,
        desc: message,
      };
      const response = await sendEmail(obj);
      if (response) {
        if (response.status === 200) {
          setSuccessMsg("We received your request, Keep an eye on your email.");

          setTimeout(() => {
            setModal(!modal);
            document.body.classList.remove("overflow-hidden");
            setSuccessMsg("");
            setEmail("");
            setMessage("");
            setIsSending(false);
          }, 3000);
        } else {
          setTimeout(() => {
            setIsSending(false);
          }, 1000);
        }
      } else {
        console.log("some issue");
      }
    }
  };
  const handleEscapeKey = (event) => {
    if (event.key === "Escape" || event.key === "Esc") {
      setModal(false);
      clearInputValues();
      document.body.classList.remove("overflow-hidden");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);
  const clearInputValues = () => {
    setMessage("");
    setEmail("");
    setErrors({});
  };
  const handleClickOutside = (e) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(e.target) &&
      !contactRef.current.contains(e.target)
    ) {
      setModal(false);
      document.body.classList.remove("overflow-hidden");
      clearInputValues();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="footer-part flex flex-col lg:flex-row items-center w-full1 justify-between mt-20 mx-12 transition-all duration-75">
        <div className="hidden lg:flex flex-col copyright justify-center items-center ">
          <img
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
            className="w-40 lg:w-52"
          />
          <p>Copyright ©2024</p>
        </div>

        <div className="text-primary ">
          <a
            href="/help/guidelines"
            target="_blank"
            className="transition-all duration-200 hover:opacity-80"
          >
            Community Guidelines
          </a>
        </div>
        <div>
          <p className="text-base lg:text-[1.2rem]">•</p>
        </div>

        <div className="text-primary">
          <a
            href="/help/privacypolicy"
            target="_blank"
            className="transition-all duration-200 hover:opacity-80"
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <p className="text-base lg:text-[1.2rem]">•</p>
        </div>
        <div className="text-primary">
          <a
            href="/help/termsofuse"
            target="_blank"
            className="transition-all duration-200  hover:opacity-80"
          >
            Terms & Conditions
          </a>
        </div>
        <div>
          <p className="text-base lg:text-[1.2rem]">•</p>
        </div>

        <div className="text-primary" ref={contactRef}>
          <button
            onClick={(e) => {
              setModal(!modal);
              document.body.classList.add("overflow-hidden");
            }}
            className="transition-all duration-200 hover:opacity-80"
          >
            Contact
          </button>
        </div>

        <div className="social-icons items-center flex gap-5 my-8 lg:my-1">
          <a
            className="icon transform transition-transform duration-150 hover:scale-105"
            href="https://www.tiktok.com/@joindubs"
            target="_blank"
          >
            <img src="/images/music.svg" />
          </a>
          <a
            className="icon transform transition-transform duration-150 hover:scale-105"
            href="https://www.instagram.com/joindubs/"
            target="_blank"
          >
            <img src="/images/instagram.svg" />
          </a>
          <a
            className="icon transform transition-transform duration-150 hover:scale-105"
            href="https://www.youtube.com/@joindubs"
            target="_blank"
          >
            <img src="/images/youtube.svg" />
          </a>
        </div>
        <div className="flex flex-col  mobile-copyright justify-center items-center mt-4 mb-8 lg:my-1 lg:hidden">
          <img
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
            className="cursor-pointer w-40 lg:w-52"
          />
          <p>Copyright ©2024</p>
        </div>
      </div>

      {/* popup modl starts here  */}
      <div
        id="modal"
        style={{ display: modal ? "flex" : "none" }}
        className="footer-main-page fixed inset-0 flex items-center( justify-center bg-black bg-opacity-50 z-[999] items-center transition-all duration-75"
      >
        <div
          className="bg-[#350266] rounded-lg px-4 lg:px-8 py-8 w-[90%] md:w-[50%] lg:w-[40%] 2xl:w-[30%] w-30 pop-bg"
          ref={popupRef}
        >
          <div className="w-full text-right relative top-[-15px] right-[-15px]">
            <button
              onClick={(e) => {
                document.body.classList.remove("overflow-hidden");

                setModal(!modal);
                clearInputValues();
              }}
              type="button"
              className="leading-[1rem] w-[24px] h-[24px]  bg-[#FAFAFA33] text-[#350266] rounded-full font-bold  transition-all duration-200  hover:bg-primary mr-4 lg:mr-0"
            >
              <span className="align-text-top-x">X</span>
            </button>
          </div>
          <div className="art-img">
            <img src="/images/art.svg" className="mx-auto" />
          </div>
          <div className="heading text-center lg:px-3">
            <h2 className="text-white text-[24px] font-bold">Get in Touch</h2>
            <p className="text-white text-xl font-normal leading-[1] mb-3">
              We'd love to hear your thoughts and questions. Connect with us!
            </p>
          </div>
          <div className="input-button mt-4 flex flex-col lg:mx-61 border-[1.75px]  border-[#FBF638] rounded-[8px] bg-[#350266] border-solid px-5 py-[0.7rem] relative">
            <label
              className="text-[#FAFAFA99] text-[14px] font-[400] absolute cursor-pointer top-1/2 -translate-y-1/2 email-label transition-all duration-500 "
              for="email"
            >
              Email
            </label>
            <input
              className="w-auto bg-transparent text-[#FAFAFA] custom-placeholder text-[16px] font-[400] pt-6 pl-[3px]"
              // placeholder="Enter your Email"
              type="email"
              id="email"
              onChange={(e) => handleChange(e)}
              value={email}
              maxLength={200}
              onFocus={() => {
                document.querySelector(".email-label").style.transform =
                  "translateY(-24px)";
                document.querySelector(".email-label").style.fontSize = "13px";
              }}
              onBlur={() => {
                if (email.trim() === "") {
                  document.querySelector(".email-label").style.transform =
                    "translateY(-50%)";

                  document.querySelector(".email-label").style.fontSize =
                    "16px";
                }
              }}
            />
          </div>
          <p className="text-base mt-2 text-[#ff557e] pl-1">{errors?.email}</p>

          <div className="input-button mt-5 flex flex-col mx-61 border-[1.75px] border-[#FBF638] rounded-[8px] bg-[#350266] border-solid  pl-5 pt-[0.7rem] relative">
            <label
              className="text-[#FAFAFA99] text-[14px] font-[400] absolute cursor-pointer top-1/2 -translate-y-1/2 message-label transition-all duration-500 "
              for="message"
            >
              Your Message
            </label>
            <textarea
              id="message"
              rows="4"
              className="block w-auto bg-transparent text-[#FAFAFA] custom-placeholder text-[16px] font-[400] pr-4 pb-[0.7rem] custom-scroll resize-none mt-9 pl-[3px]"
              maxLength={500}
              onChange={(e) => handleChange(e)}
              value={message}
              onFocus={() => {
                document.querySelector(".message-label").style.transform =
                  "translateY(-58px)";
                document.querySelector(".message-label").style.fontSize =
                  "13px";
              }}
              onBlur={() => {
                if (message.trim() === "") {
                  document.querySelector(".message-label").style.transform =
                    "translateY(-50%)";
                  document.querySelector(".message-label").style.fontSize =
                    "16px";
                }
              }}
            ></textarea>
          </div>
          <p className="text-base mt-2 text-[#ff557e] pl-1">
            {errors?.message}
          </p>
          {/* <p className="text-base my-2 text-green-700 pl-1">{successMsg}</p> */}
          {successMsg && (
            <div class=" p-2 flex items-center space-x-2">
              {" "}
              <svg
                class="w-4 h-4 bg-[#52E74D] text-[#350266] rounded-full"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {" "}
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5 13l4 4L19 7"
                ></path>{" "}
              </svg>{" "}
              <span class="text-[#52E74D] text-[14px]">{successMsg}</span>{" "}
            </div>
          )}
          <div className="join text-center cursor-pointer flex justify-center items-center lg:mx-61">
            {/* <button
              onClick={(e) => { setModal(!modal) 
                clearInputValues()

              }}
              className="bg-[transparent] px-4 py-2 rounded-full font-bold w-[15rem] xs:w-17rem] md:w-[20rem] h-12 text-[16px] text-[#fcfcfc] border-[#FBF638] border border-solid mx-2 hover:bg-[#FBF638] hover:text-black"
            >
              Cancel
            </button> */}
            <button
              onClick={(e) => sendEmailFunction(e)}
              className="bg-[#FBF638] px-4 py-2 rounded-full font-bold w-32 xs:w-40 h-12 text-[16px] text-[#350266] mt-3 btn-hvr "
              disabled={isSending}
            >
              Send
            </button>
          </div>
        </div>
      </div>
      {/* popup modal ends here  */}
    </>
  );
};
export default FooterMain;
