import "../../Styles/Parentalconfirmation.css";
import { useEffect, useState } from "react";
import PILImage from "../PILImage";
import { useLocation } from "react-router-dom";

const ParentalVerificationFailure = () => {
  const location = useLocation();
  const data = location.state;
  const [msgText, setMsgText] = useState("");
  useEffect(() => {
    document.body.style.background = "#350266";

    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    const formattedMessage = data?.message?.replace(
      emailRegex,
      '<a href="mailto:$1" class="text-primary">$1</a> '
    );

    setMsgText(formattedMessage);
  }, [location]);

  return (
    <>
      <div className="parental-confirmation">
        <div className="logo-part flex pt-5 justify-center">
          <img src="/images/dubslogo.svg" width={140} height={40} />
        </div>

        <div className="content-part flex  flex-col  justify-center items-center h-full lg:h-auto">
          <PILImage
            lowQaulityImg="/images/denied_low.png"
            highQualityImg="/images/parental_verfication_success.svg"
            padding="5rem"
          />
          <div className="content text-center">
            <div className="heading">
              <h2>Verification Failure</h2>
            </div>
            <div className="text-center">
              {msgText ? (
                <p dangerouslySetInnerHTML={{ __html: msgText }} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ParentalVerificationFailure;
