import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Terms from "./Components/Pages/Term";
import Privacy from "./Components/Pages/Privacy";
import Guideline from "./Components/Pages/Guideline";
import Landingpage from "./Components/Pages/Landingpage";
import Home from "./Components/Pages/Home";
import Parentalconfirmation from "./Components/Parental_verification/ParentalConfirmation";
import Parentaldenied from "./Components/Parental_verification/Parentaldenied";
import Parentalaccepted from "./Components/Parental_verification/Parentalaccepted";
import ParentalVerificationFailure from "./Components/Parental_verification/ParentalVerificationFailure";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path={"/verification/success"} element={<Landingpage />} />
          <Route path={"/help/termsofuse"} element={<Terms />} />
          <Route path={"/help/privacypolicy"} element={<Privacy />} />
          {/* <Route path={"/home"} element={<Home />} /> */}
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/parentalconfirmation"
            element={<Parentalconfirmation />}
          />
          <Route exact path="/parentaldenied" element={<Parentaldenied />} />
          <Route
            exact
            path="/parentalaccepted"
            element={<Parentalaccepted />}
          />
          <Route
            exact
            path="/verificationfailure"
            element={<ParentalVerificationFailure />}
          />
          <Route exact path="/help/guidelines" element={<Guideline />} />

          <Route exact path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
