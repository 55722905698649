import { useRef, useState } from "react";
import "../../Styles/Home.css";
import { useEffect } from "react";
import Joinus from "./Joinus";
import FooterMain from "./FooterMain";
const Home = () => {
  const videoRef = useRef(null);
  // const videoRef2 = useRef(null);
  const mobileVideoRef = useRef(null);
  const joinInputRef = useRef(null);
  // let flag1 = false;
  // let flag2 = false;
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [desktopVideoLoaded, setDesktopVideoLoaded] = useState(false);
  const [desktopVideoPlaying, setDesktopVideoPlaying] = useState(false);

  const imageRef = useRef(null);

  const fixedDivRef = useRef(null);
  const userAgent = navigator.userAgent.toLowerCase();
  const updateHeightOfVideo = () => {
    if (mobileVideoRef.current) {
      const videoHeight = mobileVideoRef?.current?.clientHeight;
      const offset = (videoHeight * 2.6) / 100;
      fixedDivRef.current.style.marginTop = `-${offset}rem`;
    }
  };
  const updateHeightOfImage = () => {
    if (imageRef.current) {
      const imageHeight = imageRef?.current?.clientHeight;
      const offset = (imageHeight * 2.6) / 100;
      fixedDivRef.current.style.marginTop = `-${offset}rem`;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    document.body.style.background = "#350266";
    updateHeightOfImage();
    window.addEventListener("resize", updateHeightOfVideo);

    return () => {
      window.removeEventListener("resize", updateHeightOfVideo);
    };
  }, []);
  const handleJoinScrollToViewFirefox = () => {
    document.getElementById("join-div").scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      joinInputRef.current.focus();
    }, 200);
  };
  const handleJoinScrollToView = () => {
    document.getElementById("join-div").scrollIntoView({ behavior: "smooth" });
    joinInputRef.current.focus();
  };

  return (
    <>
      <div className={`home-page`}>
        <div
          id="dubs-logo"
          class="lg:mx-12 lg:my-4 hidden lg:flex justify-between items-center"
        >
          <div className="logo-part ">
            <img
              src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubslogo-tm.svg"
              className="w-40 lg:w-52"
            />
          </div>
          <div className=" z-30">
            <button
              className="text-[18px] font-bold rounded-full bg-[#fbf638] py-2 px-8 text-[#350266] h-12 btn-hvr"
              onClick={
                userAgent.indexOf("firefox") > -1
                  ? handleJoinScrollToViewFirefox
                  : handleJoinScrollToView
              }
            >
              Join the waitlist
            </button>
          </div>
        </div>

        <div className="video-section  absolute hidden lg:block lg:w-[65%] lg:top-[-2%]  lg:-right-1/4 xl:right-[-22%] xl:w-[56%] 3xl:w-1/2 3xl:right-[-20%]">
          <img
            src="/images/desktop_image_1080.jpg"
            alt="video image"
            className={` h-auto rounded-full  ${
              desktopVideoLoaded ? "" : "low-quality"
            } ${desktopVideoPlaying ? "invisible" : "visible"}`}
            // ref={fixedDivRef}
            ref={imageRef}
          />
          <video
            className="h-auto rounded-full absolute z-10 top-0 left-0"
            preload="auto"
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/dubs.mp4"
            ref={videoRef}
            muted
            loop
            autoPlay
            playsInline
            controls={false}
            onLoadedData={() => {
              setDesktopVideoLoaded(true);
            }}
            onPlaying={() => {
              setDesktopVideoPlaying(true);
            }}
          ></video>
        </div>
        <div
          className="mobile-new-video  lg:hidden relative ml-[-16%] w-[132%]  xs:ml-[-8%] xs:w-[116%]  sm:w-[112%] sm:ml-[-6%] md:w-[108%] md:ml-[-4%]"
          ref={fixedDivRef}
        >
          <img
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/mobile_logo_image_1080.jpg"
            alt="video image"
            className={`w-full h-auto rounded-full  low-quality ${
              videoLoaded ? "invisible" : "visible"
            } min-h-[380px] xs:min-h-[420px] sm:min-h-[620px] md:min-[720px]:`}
            ref={imageRef}
            onLoad={updateHeightOfImage}
          />
          <video
            src="https://d28n1zg2scc8nu.cloudfront.net/dubs/v2/with_Logo/mobile/KeyArt-logo_1min_mobile_1920x1920.mp4"
            muted
            autoPlay
            loop
            playsInline
            controls={false}
            onCanPlay={() => {
              setVideoLoaded(true);
              updateHeightOfVideo();
            }}
            className="w-full h-auto rounded-full absolute top-0 left-0 z-40"
            ref={mobileVideoRef}
          ></video>
        </div>
        {/* mobile content starts here */}
        <div
          className={`flex content flex-col gap-[0.7rem] z-[999] relative mt-5  lg:ml-[5rem] 2xl:ml-[7rem] items-center lg:hidden `}
        >
          <div className=" text-[#fbf638] font-bold text-[1.7rem] xs:text-[2rem] ">
            <h2 className="leading-[1] text-center lg:text-left">
              Find Awesome People <br />
              to Game With!
            </h2>
          </div>
          <div className=" flex gap-[1rem] sub-header items-center ">
            <img
              src="/images/handshake-mobile.png"
              className="w-[48px] h-[48px]"
            ></img>
            <p className="text-sm xs:text-base">
              Vibe with gamers who play like you
            </p>
          </div>
          <div className="flex gap-[1rem] sub-header items-center">
            <img
              src="/images/gamepad-mobile.png"
              className="w-[48px] h-[48px]"
            ></img>
            <p className="text-sm xs:text-base">
              Set up your gaming sesh in no time
            </p>
          </div>
          <div className=" flex gap-[1rem] sub-header items-center">
            <img
              src="/images/dubloons-mobile.png"
              className="w-[48px] h-[48px]"
            ></img>
            <p className="text-sm xs:text-base">
              Turn every action into fun rewards
            </p>
          </div>
          <div className="text-center w-full lg:w-fit mx-6 lg:mx-0 my-10">
            <button
              className="text-[18px] font-bold rounded-full bg-primary py-2 px-8 text-[#350266] w-[17rem] h-12 btn-hvr"
              onClick={
                userAgent.indexOf("firefox") > -1
                  ? handleJoinScrollToViewFirefox
                  : handleJoinScrollToView
              }
            >
              Join the waitlist
            </button>
          </div>
        </div>
        {/* mobile content ends here*/}
        {/* desktop content starts here */}

        <div className="hidden lg:flex content flex-col gap-[1.5rem] z-[999] relative  ml-14  mt-28 ">
          <div className=" text-primary font-bold vs:mt-[0rem] xs:mt-[1rem] sm:mt-[5rem] md:mt-[13rem] lg:mt-[0rem] lg:mr-8 text-center lg:text-left">
            <h1 className="leading-[1] text-[2rem] lg:text-[3rem] 2xl:text-[4.5rem]">
              Find Awesome People <br />
              to Game With!
            </h1>
          </div>
          <div className=" flex gap-[1rem] sub-header items-center">
            <img
              src="/images/handshake@2x.svg"
              className="w-[48px] h-[48px]"
            ></img>
            <p>Vibe with gamers who play like you</p>
          </div>
          <div className=" flex gap-[1rem] sub-header items-center">
            <img
              src="/images/gamepad@2x.svg"
              className="w-[48px] h-[48px]"
            ></img>
            <p>Set up your gaming sesh in no time</p>
          </div>
          <div className=" flex gap-[1rem] sub-header items-center">
            <img
              src="/images/dubloons@2x.svg"
              className="w-[48px] h-[48px]"
            ></img>
            <p>Turn every action into fun rewards</p>
          </div>
          <div className="text-center w-full lg:w-fit mx-6 lg:mx-0 my-10">
            <button
              className="text-[18px] font-bold rounded-full bg-primary py-2 px-8 text-[#350266] h-12 btn-hvr"
              onClick={
                userAgent.indexOf("firefox") > -1
                  ? handleJoinScrollToViewFirefox
                  : handleJoinScrollToView
              }
            >
              Join the waitlist
            </button>
          </div>
        </div>
        {/* desktop content ends here */}

        <div id="join-div">
          <Joinus joinInputRef={joinInputRef} />
        </div>

        <FooterMain />
      </div>
    </>
  );
};
export default Home;
